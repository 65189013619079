.Accounts {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: 450px) {
      justify-content: flex-start;
    }
  }

  &__item {
    width: 80%;
    margin: 0.5rem;

    @media screen and (min-width: 450px) {
      width: calc(50% - 1rem);
    }

    @media screen and (min-width: 750px) {
      width: calc(100% / 3 - 1rem);
    }
  }
}