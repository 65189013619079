.LayoutSwitcher {
  &.MuiButtonGroup-root {
    background-color: white;
  }

  &__button {
    &.MuiButton-root {
      background-color: var(--color-white);
      color: var(--color-darker);
      border: var(--border-style);
      width: 2.5rem;
    }

    &.MuiButtonGroup-grouped {
      min-width: 10px;
    }

    &--active {
      &.MuiButton-root {
        background-color: var(--color-acсent);
        color: var(--color-brand);
      }
    }
  }

  &__icon {
    &.MuiSvgIcon-root {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
