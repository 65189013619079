@import "../_helpers/icons-generator";

.Icon {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  vertical-align: middle;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  font-size: inherit;
  overflow: hidden;
  user-select: none;
}

.Icon-description {
  margin-top: 0.9rem;
}

.Icon_edit {
  cursor: pointer;
  display: inline;
  position: relative;
  color: var(--color-dark);

  .Label {
    font-size: var(--font-size--xs);
    cursor: inherit;
    color: inherit;
    line-height: var(--font-size--m);
  }
}

.Icon-editable,
.Icon-editable + .Label {
  color: var(--color-brand);
}

.Icon_imgRemove {
  padding-bottom: 2px;
}
