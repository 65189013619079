:root {
  --header-bg-color: var(--color-brand);

  --side-menu-width: 2.875rem;

  --border-style: 1px solid var(--color-light);
  --border-radius-xs: 3px;
  --border-radius-s: 6px;
  --border-radius-m: 10px;

  --shadow-focus-full: 0 0 2px 2px var(--color-focus);
  --shadow-card: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --shadow-card-active: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  --shadow-alert: 0 4px 8px 0;
  --shadow-snack: 0px 2px 4px 0px;
  --shadow-dialog: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14);
  --shadow-mobile-header: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  --shadow-suggestion-card: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.14);

  --z-index-overlay: 1;
  --z-index-snack: 23;
  --z-index-alert: 10;
  --z-index-menu: 10;
  --z-index-submenu: 15;
  --z-index-dialog: 1201; // cause header z-index value in .MuiAppBar-root 1100
  --z-index-dialog-leave: 25;

  --padding-wizard-search-screen-logo-container: 22px 0px 40px;

  --app-logo: url("/img/UpKeepr_Wordmark.svg");
  --not-found-404: url("../../../images/brands/default/pic_404.svg");
  --something-went-wrong: url("../../../images/brands/default/pic_something_went_wrong.svg");
  --full-drop-zone-csv: url("../../../images/brands/default/full_drop_zone_CSV.svg");
  --save-connection: url("../../../images/brands/default/pic_save_connection.svg");
  --empty-state-wizard-search: url("../../../images/brands/default/empty_state.svg");
  --wizard-success-loading: url("../../../images/brands/default/wizard_success_loading_bg.svg");
  --verify-email: url("../../../images/brands/default/verify_email.svg");
  --wizard-welcome-img: url("../../../images/brands/default/wizard-welcome.png");
}

@mixin scrollbar($element) {
  #{$element}::-webkit-scrollbar {
    width: 4px;
  }

  #{$element}::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius-m);
    background-color: var(--color-brand);
  }
}
