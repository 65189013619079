.AssetIcons {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 20rem;

  &::after {
    content: "";
    flex: auto;
  }

  &__item {
    border: 1px solid transparent;

    .Icon {
      display: block;
      padding: 12px;
      font-size: 1.5rem;
      color: var(--color-brand);
      user-select: none;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid var(--color-acсent);
    }

    &.is-active {
      background-color: var(--color-acсent);
    }
  }
}

@include scrollbar(".AssetIcons");
