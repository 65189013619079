.NoResults {
  position: absolute;
  // background-color: var(--color-bg);
  width: 100%;
  height: 65%;
  bottom: 0;
  right: 0;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--color-dark);
  user-select: none;

  &__fixed {
    position: fixed;
    justify-content: start;
    left: 0;
    top: 180px;
    background: var(--color-bg);
    pointer-events: none;
  }

  &_Text {
    font-size: var(--font-size--s);
    line-height: var(--font-size--l);
    letter-spacing: 0.15px;
    font-weight: normal;
  }

  &_Title {
    font-size: var(--font-size--m);
    line-height: 1.875rem;
    letter-spacing: 0.15px;
    font-weight: 500;
    color: var(--color-text);
    margin-top: 0.85rem;

    &__subTitle {
      font-size: var(--font-size--s);
      font-weight: 400;
      color: var(--color-gray);
      margin-top: 4px;
    }
  }

  &_Subtitle {
    font-size: var(--font-size);
  }

  &_List {
    margin: 0;
    padding-left: 25px;
  }

  &_AssetImg {
    max-width: 100%;
    height: 240px;
    width: 240px;
  }

  &_ActivityImg {
    margin-top: 3%;
    width: 240px;
    height: 164px;
  }

  &_VendorImg {
    height: 240px;
    width: 240px;
  }

  &_assets {
    padding-left: var(--side-menu-width);
    max-height: 180px;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;

    @media screen and (max-width: 600px) {
      padding-left: 0;
    }
  }

  &_vendors {
    max-height: 180px;
    margin: auto;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
  }

  &_projects {
    margin: auto;
    top: 0;
  }
}
