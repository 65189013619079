.NavFilter {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_filterIcon {
    font-size: 18px;
    color: var(--color-darker);
  }

  &_title {
    margin: 16px;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-transform: capitalize;
    color: var(--color-dark);
  }

  &_clearButton {
    min-width: 0;
    padding: 0 var(--space-size--m);
    margin-right: var(--space-size--xs);
    height: auto;
    user-select: none;
    outline: none;

    &.Button-link {
      width: auto;
    }
  }

  &_clearIconContainer {
    position: relative;
    padding: var(--space-size--xs);
  }

  &_assetTypeList {
    margin: 0;
    padding: 0 8px;
    list-style: none;
  }

  &_assetType {
    &-expanded {
      .NavFilter_assetTypeTitle,
      .NavFilter_assetTypeTitle:hover {
        background-color: var(--color-brand-secondary-selected);

        .NavFilter_assetTypeName,
        .NavFilter_assetTypeIcon {
          color: var(--color-brand-secondary);
        }
      }
      .NavFilter_assetTypeTitle::after {
        transform: rotate(-180deg);
      }
    }
  }

  &_assetTypeTitle {
    display: flex;
    position: relative;
    align-items: center;
    height: 44px;
    margin-bottom: 8px;
    padding: 0 8px;
    border-radius: var(--border-radius-s);

    &::after {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      margin-top: -0.45rem;
      pointer-events: none;
      font-size: 0.75rem;
      content: "\25BE";
      color: var(--color-dark);
      transform: rotate(0);
      transition: all ease 0.3s;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--color-bg-selected);

      .NavFilter_assetTypeName,
      .NavFilter_assetTypeIcon {
        color: var(--color-brand);
      }
    }
  }

  &_assetTypeIcon {
    margin-right: 16px;
    color: var(--color-darker);
    transition: all ease 0.3s;
  }

  &_assetTypeName {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text);
    transition: all ease 0.3s;
  }

  &_itemsList {
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    transition: max-height ease-in-out 0.3s;

    &::-webkit-scrollbar {
      display: none;
    }

    &-isVisible {
      max-height: 200px;
      border-bottom: 1px solid var(--color-light);
    }
  }

  &_item {
    display: flex;
    align-items: center;
    height: 52px;
    margin: 4px 0;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 400;
    border-radius: var(--border-radius-s);

    &:hover {
      cursor: pointer;
      background-color: var(--color-bg-selected);
    }

    &-isActive,
    &-isActive.NavFilter_item:hover {
      background-color: var(--color-acсent);
    }
  }

  &_itemImg {
    margin-right: 18px;
    width: 28px;
    height: 28px;
    border-radius: var(--border-radius-xs);
    background-color: #cadcf6;
  }
}
