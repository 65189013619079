.Input-search {
  height: var(--form-item-height);
  font-size: var(--font-size);

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-dark);
  }
}

.Input {
  &.Input-block {
    display: block;
    width: 100%;
  }

  &-search {
    border: var(--border-style);
    border-radius: var(--border-radius-xs);
    width: 100%;
    padding: var(--space-size--xs) 3rem var(--space-size--xs) var(--space-size--s);

    &:focus {
      box-shadow: var(--shadow-focus-full);
    }
  }

  &.Input-date {
    width: auto;
    cursor: pointer;
    position: relative;
    margin-left: 0;
    padding-left: calc(var(--space-size--m) + var(--space-size--m));

    &::before {
      position: absolute;
      left: 0;
      top: 5px;
      content: "event";
      display: block;
      width: 16px;
      height: 16px;
      overflow: hidden;
      font-family: "Material Icons Outlined";
      color: var(--color-brand);
    }

    .Input_field {
      padding-right: 0.25rem;
    }

    &.is-completed {
      text-decoration: line-through;
      color: var(--color-success);

      &::before {
        content: "event_available";
        color: var(--color-success);
      }
    }

    &.close_to_date {
      color: var(--color-warning);

      &::before {
        content: "timer";
        color: var(--color-warning);
      }
    }

    &.overdue {
      color: var(--color-error);

      &::before {
        content: "timer_off";
        color: var(--color-error);
      }
    }

    &.notRequired {
      margin-bottom: 0;
    }
  }

  &_activityTypeWrapper,
  &_assetTypeWrapper {
    display: inline;
    position: relative;
  }

  &.activeInput {
    .Bar::before,
    .Bar::after {
      width: 50%;
    }
  }

  &.withoutGap {
    margin-left: 0;
  }
}

.Input {
  display: inline-block;
  position: relative;
  margin-top: var(--space-size--s);
  margin-bottom: var(--space-size--l);

  &_field {
    font-size: var(--font-size);
    padding: 0.25rem 2rem 0.25rem 0;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--color-dark);
    background-color: inherit;

    &:focus {
      outline: none;
    }

    /* active state */
    &:focus ~ .Input_label,
    &.has-value ~ .Input_label,
    &:placeholder-shown ~ .Input_label {
      top: -0.75rem;
      font-size: var(--font-size--xs);
      color: var(--color-brand);
    }

    &::placeholder {
      color: var(--color-dark);
    }

    &:disabled ~ .Input_label {
      color: var(--color-dark);
    }

    /* active state */
    &:focus ~ .Bar:before,
    &:focus ~ .Bar:after {
      width: 50%;
    }

    &.has-error {
      border-color: var(--color-error);
    }
  }

  &_label {
    position: absolute;
    font-size: var(--font-size);
    color: var(--color-dark);
    font-weight: normal;
    text-transform: capitalize;
    pointer-events: none;
    top: 0.25rem;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  &_errorLabel,
  &_requiredLabel {
    position: absolute;
    font-size: var(--font-size--xs);
    margin: 4px 0 var(--space-size--xs);
    color: var(--color-dark);
  }

  &_errorLabel {
    color: var(--color-error);
    opacity: 0.8;
  }

  &_readonly {
    padding-right: 0;
  }

  .ClearIcon {
    position: absolute;
    bottom: calc(45% - var(--space-size--xs));
    right: var(--space-size--xs);

    .Icon {
      color: var(--color-dark);
    }

    &:hover {
      cursor: pointer;
    }
  }
}
