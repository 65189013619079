.MobileMenu {
  position: fixed;
  width: 270px;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: auto;
  transform: translateX(-270px);
  background-color: var(--color-white);
  z-index: var(--z-index-menu);
  transition: all cubic-bezier(0,.37,.6,.99) .3s;

  &-isOpened {
    transform: translateX(0);
    box-shadow: 0 11px 15px rgba(0, 0, 0, 0.2), 0 9px 46px rgba(0, 0, 0, 0.12), 0 24px 38px rgba(0, 0, 0, 0.14);
  }

  &_header {
    padding: 16px;
  }

  &_logo {
    height: 24px;
  }

  &_navigation {
    .Navigation_item {
      &:last-child {
        border-bottom: 1px solid var(--color-light);
      }
    }

    .Navigation_link {
      padding: 0 16px;
      line-height: 48px;
    }

    .Navigation_link-isActive,
    .Navigation_link-isActive.Navigation_link:hover{
      background-color: var(--color-acсent);
    }

    .Navigation_link:hover {
      background-color: var(--color-bg-selected);
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    display: none;
  }
}

@include scrollbar('.MobileMenu');
