.Button,
.Button-primary,
.Button-secondary,
.Button-link,
.Button-icon {
  height: var(--form-item-height);
  min-width: 8rem;
  padding: 5px;
  font-size: var(--font-size);
  border-radius: var(--border-radius-xs);
  position: relative;
  vertical-align: middle;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;

  &_s {
    min-width: 6.75rem;
  }

  & + & {
    margin-left: var(--space-size--s);
  }

  &:active,
  &:focus {
    box-shadow: none;
    outline: 0;
  }

  .Icon {
    color: inherit;
    font-size: var(--font-size--xl);
    padding-right: 5px;
    padding-bottom: 3px;
  }

  .Loader {
    margin-top: 3px;
  }

  @media screen and (max-width: 380px) {
    min-width: auto;
    width: 100%;
  }
}

.Button {
  &-primary {
    background-color: var(--color-brand);
    border-color: var(--color-brand);
    color: var(--color-white);

    &[disabled] {
      background-color: var(--color-acсent);
      border-color: var(--color-acсent);
      color: var(--color-brand-light);
    }

    &:hover:not([disabled]) {
      box-shadow: 0 2px 4px 0 var(--color-dark);
    }
  }

  &-secondary {
    background-color: var(--color-bg);
    border-color: var(--color-brand);
    color: var(--color-brand);

    &[disabled] {
      background-color: var(--color-bg);
      border-color: var(--color-acсent);
      color: var(--color-acсent);
    }

    &:hover:not([disabled]) {
      background-color: var(--color-acсent);
    }
  }

  &-link {
    position: relative;
    color: var(--color-brand);
    font-size: var(--font-size);
    border: none;
    vertical-align: inherit;

    &:hover:not([disabled]) {
      background-color: var(--color-acсent);
    }

    &:active,
    &:focus {
      outline: none;
    }

    &[disabled] {
      color: var(--color-acсent);
    }

    &-warning {
      @include indicator(5px, -3px);
    }
  }

  &-icon {
    min-width: 0;
    padding: 0 1rem;
    border: none;
    color: var(--color-darker);

    &:hover {
      color: var(--color-error);
    }
  }
}

.DeleteButton {
  color: var(--color-darker);

  &:hover:not([disabled]) {
    background-color: var(--color-bg);
  }
}

.DisabledButton {
  color: var(--color-darker);
}

.CreateItem {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  min-width: fit-content;
  padding: 0;
  box-shadow: var(--shadow-mobile-header);

  .Icon {
    padding: 0;
  }
}

.ButtonGroup {
  overflow: auto;
  margin: var(--space-size--xl) 0;

  > .Button,
  > .Button-secondary,
  > .Button-primary {
    float: right;
  }
}
