:root {
  /* Theme colors */
  --color-brand: #26b6f9;
  --color-acсent: #e3efff;
  --color-bg: #f6f5fb;
  --color-bg-selected: #f6fcff; /* mix(white, #26B6F9, 95%) */
  --color-light: #e9e9e9; /* mix(white, #212121, 90%) */
  --color-dark: #bfbfbf; /* mix(white, #212121, 70%) */
  --color-darker: #666666;
  --color-text: #212121;
  --color-brand-light: #9dd8f8;
  --color-brand-secondary: #8bc34a;
  --color-brand-secondary-selected: #e9fae1;
  --color-bg-main: #ffffff;
  --color-bg-secondary: #fafdff;
  --color-bg-image: #cbddf6;
  --color-gray: #7e7d82;
  --color-on-surface3: #E4E1E1;

  /* Base colors */
  --color-error: #f2133c;
  --color-warning: #ff9800;
  --color-warning-lighten: #f6c329;
  --color-success: #8ec549;
  --color-white: #fff;
  --color-black: #000;
  --color-transparent: transparent;
  --color-sub-menu-focus: var(--color-acсent);
  --color-menu-focus: var(--color-success);
  --color-focus: #1e9ff0;

  /* Status colors */
  --color-to-do: #26B6F6;
  --color-in-progress: #9874fe;
  --color-done: #8ec549;
  --color-cancelled: #f2133c;
}
