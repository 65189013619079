.RadioButton {

  &.MuiIconButton-root {
    padding: 9px;
  }

  .MuiSvgIcon-root  {
    font-size: 1rem;
  }

  &__label {
    width: auto;
  }
}