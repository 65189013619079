$icons: "add", "arrow_back", "calendar_today", "close", "delete", "description", "edit",
  "exit_to_app", "expand_less", "expand_more", "event", "event_available", "https", "mail_outline",
  "menu", "save", "search", "sentiment_satisfied", "clear", "vpn_key", "chevron_left", "repeat",
  "timer", "today", "keyboard_arrow_down", "filter_list", "menu_open", "keyboard_arrow_up",
  "room_service", "remove_circle_outline", "shopping_basket";

@each $icon in $icons {
  .Icon-#{$icon}::before {
    content: "#{$icon}";
  }
}
