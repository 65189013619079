@mixin side-menu-item {
  padding: var(--space-size--xs);
  cursor: pointer;
  text-align: center;

  .Icon {
    pointer-events: none;
  }
}

.SideMenu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--side-menu-width);
  font-size: 24px;
  background-color: var(--color-brand);
  color: var(--color-white);
  z-index: 1201;

  @media print {
    display: none;
  }

  &_action {
    color: var(--color-white);
    width: var(--side-menu-width);
    position: relative;
    height: var(--space-size--xl);
    padding: var(--space-size--xs);
    background-color: var(--color-focus);
    font-size: var(--font-size--xl);
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    &-warning {
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 10px;
        bottom: 9px;
        right: 10px;
        transform: rotate(45deg);
        background-color: var(--color-warning-lighten);
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 10px;
        bottom: 9px;
        right: 10px;
        transform: rotate(-45deg);
        background-color: var(--color-warning-lighten);
      }
    }
  }

  &_types {
    @include side-menu-item;

    &-selected {
      @include side-menu-item;
      background-color: var(--color-menu-focus);
    }

    &:hover {
      background-color: var(--color-focus);
    }
  }
}
