.Filter {
  display: flex;
  align-items: center;

  &__label {
    text-transform: none;
    color: var(--color-dark);
    font-size: var(--font-size--s);
    margin: 0 0.5rem;
  }

  &__div {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    color: var(--color-brand);
    background-color: inherit;
    padding: 2px 15px 2px 4px;
    direction: rtl;
    font-size: var(--font-size--s);

    &:focus {
      outline: none;
    }
  }
}
