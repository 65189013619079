//
@media (max-width: $screen-xs-max) {
  .EditPage {
    &.Form {
      overflow-y: auto;
      padding: 0;
    }

    .Form_heading {
      display: none;

      .Button-link,
      .Form_buttons {
        display: none;
      }
    }

    .Form_fieldsCol {
      .Section_DeleteButton,
      .Section_LogOutButton {
        display: none;
      }
    }

    .Form_imgCol {
      .Section_DeleteButton,
      .Section_LogOutButton {
        margin: var(--space-size--l) 0;
        display: flex;
        border-top: var(--border-style);
      }

      .Section_LogOutButton {
        padding-top: 10px;
      }
    }

    .Form_content {
      padding-top: var(--space-size--l);
    }
    //
    .Section {
      padding: var(--space-size--m) calc(var(--space-size--m) - 4px) var(--space-size--m)
        var(--space-size--m);
      //      //border-bottom: var(--border-style);
      //
      &_row {
        margin: 0 0 var(--space-size--m) 0;
        flex-direction: column;

        &__noMargin {
          margin-bottom: 0;
        }

        &_mobile {
          flex-direction: row;
          justify-content: space-between;
        }
      }
      //
      &_column {
        width: 100%;
        margin-left: 0;

        &:last-child {
          margin-bottom: 0;
        }

        &_mobile {
          width: fit-content;
          width: -moz-fit-content;
          width: -webkit-fit-content;
        }

        &-withoutIcon {
          padding-left: calc(var(--space-size--m) + var(--space-size--m));
        }
      }
    }

    &_withBorder {
      border-bottom: var(--border-style);
    }

    & + .Accordion {
      border-top: var(--border-style);

      & > .Accordion_header {
        display: flex;

        .Icon {
          margin-left: auto;
        }
      }

      .Accordion_helper {
        display: none;
      }

      .CreateActivity {
        width: fit-content;
        margin-left: auto;
      }
    }
  }

  .Accordion_content {
    width: 100%;
  }
}

@media (min-width: $screen-sm-min) {
  .EditPage {
  }
}
