.Select {
  font-family: Roboto, sans-serif;
  margin-top: var(--space-size--s);
  margin-bottom: var(--space-size--l);

  &__label {
    position: absolute;
    z-index: 1;
    font-size: var(--font-size);
    font-weight: normal;
    text-transform: capitalize;
    color: var(--color-dark);
    pointer-events: none;
    top: 4px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;

    &.has-value {
      top: -0.75rem;
      font-size: var(--font-size--xs);
      color: var(--color-brand);
    }
  }

  &__value-container,
  &__indicator {
    &:hover {
      cursor: pointer;
    }
  }

  &_errorLabel,
  &_requiredLabel {
    position: absolute;
    width: 100%;
    font-size: var(--font-size--xs);
    padding: 4px 0 var(--space-size--xs);
    margin-top: -1px;
    color: var(--color-dark);
  }

  &_errorLabel {
    border-top: 1px solid var(--color-error);
    color: var(--color-error);
    opacity: 0.8;
  }

  .Select__control {
    position: relative;
    width: 100%;
    min-height: 0;
    box-shadow: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--color-dark);
    background-color: inherit;
  }

  .Select__value-container {
    padding: 0;
    font-size: var(--font-size);

    .Select__single-value {
      margin: 0;
    }
  }

  .Select__indicator {
    padding: 4px 8px;
  }

  .Select__menu {
    border-radius: 0;
    margin: 0;
    z-index: 2;
  }

  .Select__menu-list {
    padding: 0;
    background-color: var(--color-white);
    border: var(--border-style);
    border-radius: 0;
    max-height: 11.5rem;
    overflow:hidden;
    overflow-y: auto;
  }

  .Select__option {
    font-size: var(--font-size);
    padding: var(--space-size--s) var(--space-size--xs);

    &:hover {
      background-color: var(--color-acсent);
      cursor: pointer;
    }

    &--is-selected {
      background-color: var(--color-bg);
      color: var(--color-brand);
    }
  }

  .Bar {
    position: absolute;
    bottom: 0;
  }

  @include scrollbar('.Select__menu-list');
}
