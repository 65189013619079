.Radio {
  position: relative;

  &_input {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &:checked + .Radio_label {
      color: var(--color-black);

      &:before {
        border-color: var(--color-brand);
      }

      &:after {
        transform: scale(1);
      }
    }
  }

  &_label {
    display: inline-block;
    box-sizing: border-box;
    min-height: 20px;
    position: relative;
    padding: 0 var(--space-size--s) 0 calc(var(--space-size--m) + var(--space-size--m));
    margin-bottom: 0;
    vertical-align: bottom;
    transition: color 0.3s ease;
    width: 100%;

    &:empty {
      padding: 0 1rem;
      width: auto;
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.3s ease;
      display: inline-block;
    }

    &:before {
      left: 0;
      top: 4px;
      width: 12px;
      height: 12px;
      border: 1px solid var(--color-darker);
    }

    &:after {
      top: 7px;
      left: 3px;
      width: 8px;
      height: 8px;
      transform: scale(0);
      background: var(--color-brand);
    }
  }

  .Label {
    cursor: pointer;
  }
}

.status-indicator {
  &.Radio_label:before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    margin-top: -0.375rem;
    background-color: var(--color-bg-main);
  }

  &.Radio_label {
    //vertical-align: middle;
    //padding-left: var(--space-size--m);
    padding-right: 0;
    vertical-align: unset;
    &:before,
    &:after {
      top: 50%;
    }
  }

  &.Radio_input:checked + .Radio_label {
    &:before {
      border-color: var(--color-light);
    }

    &:after {
      background: var(--color-to-do);
      width: 0.625rem;
      height: 0.625rem;
      vertical-align: middle;
      left: 0.169rem;
      top: 0.45rem;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
}

.in-progress {
  .Radio_input:checked + .Radio_label:after {
    background: var(--color-in-progress);
  }
}

.done,
.finished {
  .Radio_input:checked + .Radio_label:after {
    background: var(--color-done);
  }
}

.cancelled {
  .Radio_input:checked + .Radio_label:after {
    background: var(--color-cancelled);
  }
}
