:root {
  --icon-size: 1.625rem;
  --photo-size: 2.875rem;
  --account-photo-size: 1.5rem;
}

.Account {
  display: flex;
  align-items: center;
  height: var(--space-size--xl);
  margin: 0 var(--space-size--s);
  font-size: var(--font-size);
  cursor: pointer;

  &_ico, &_ico.MuiAvatar-root, &_ico.MuiSkeleton-root {
    height: var(--icon-size);
    width: var(--icon-size);
    float: left;
    margin-right: 8px;
  }

  &__currentAccountInfo, &__currentAccountInfo.MuiTypography-body1 {
    font-size: var(--font-size);
    line-height: 20px;
    letter-spacing: 0.5px;

    &_default, &_defaul.MuiSkeleton-root {
      min-width: 60px;
    }
  }

  &__toggleIcon, &__toggleIcon.MuiSvgIcon-root {
    transition: transform .2s linear;
    transform-origin: center;

    &_open {
      transform: rotate(180deg);
    }

    &_close {
      transform: rotate(0deg);
    }
  }

  &_link {
    display: flex;
    align-items: center;
    user-select: none;
    height: fit-content;
    max-width: 100%;

    .Icon {
      padding-left: var(--space-size--xs);
      font-size: var(--font-size--xl);
      color: var(--color-dark);
      cursor: pointer;
    }
  }

  @media (max-width: $screen-xs-max) {
    width: auto;
    margin: 0;

    &_link {
      .Icon {
        display: none;
      }
    }
  }
}

.AccountAlert {
  width: 336px;
  top: var(--space-size--xl);
  right: var(--space-size--xs);

  & .MuiDialog-paper {
    //position: absolute;
    //top: var(--space-size--xl);
    //right: var(--space-size--xs);
    //width: 21rem;
    border: var(--border-style);
    border-radius: var(--border-radius-m);
    //background-color: var(--color-white);
    overflow: hidden;
    z-index: var(--z-index-alert);
    margin: 0;
  }

  .MuiDialog-container {
    display: block;
  }

  &--mobile {
    width: 90%;
    left: 5%;

    & .MuiDialog-paper {
      display: block;
      color: var(--color-text);
      //top: 100px;
      //left: 5%;
    }
  }

  &_header {
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    font-weight: bold;
    color: var(--color-brand);
    display: flex;
    padding: 0 var(--space-size--s);

    .Icon {
      font-size: var(--font-size--m);
      padding: 0 var(--space-size--xs) 0 0;
    }
  }

  &_content {
    padding: 0 var(--space-size--s) var(--space-size--l);
    overflow: auto;
    display: flex;
  }

  &_close {
    //float: right;
  }

  &_photo,  &_photo.MuiAvatar-root, &_photo.MuiSkeleton-root {
    height: var(--photo-size);
    width: var(--photo-size);
    margin-right: var(--space-size--s);
  }

  &_name {
    padding-top: 5px;

    &_default {
      min-width: 150px;
    }
  }

  &_mail {
    padding-top: 5px;
    color: var(--color-darker);
    font-size: var(--font-size--s);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &_default {
      min-width: 220px;
    }
  }

  .Link-edit {
    font-size: var(--font-size);
  }
}
