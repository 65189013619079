.StartedDialog {
  height: 100%;
  text-align: initial;
  color: var(--color-black);

  &__header{
    padding: var(--space-size--m) var(--space-size--l) var(--space-size--m);
    flex: 0 0 auto;
    margin: 0;
    font-size: var(--font-size--xl);
    line-height: 1.5rem;
    font-weight: bold;
    border-bottom: 1px solid var(--color-light);
  }

  &__info {
    padding: var(--space-size--l) var(--space-size--l) 0;
    font-size: var(--font-size);
    width: auto;
    line-height: 1.5rem;
    letter-spacing: 0.5px;
  }

  &__card-list {
    display: flex;
    margin: var(--space-size--l) 0 0;
  }

  &__note {
    padding: var(--space-size--xl) var(--space-size--l) var(--space-size--l);
    font-size: var(--font-size--s);
    line-height: var(--font-size--l);
    letter-spacing: .25px;
    color: var(--color-darker);
  }

  &__card-btn {
    width: 88px;
    height: 120px;
    border-radius: var(--border-radius-s);
    margin-left: 22px;

    &:last-child {
      margin-right: 22px;
    }
  }
}

.card-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--color-light);
  border-radius: var(--border-radius-s);
  cursor: pointer;
  transition: border-color .25s linear;

  &:hover {
    border-color: var(--color-brand-light);
  }

  &.card-btn_selected {
    border-color: var(--color-brand);
  }

  .Icon {
    font-size: 37px;
    color: var(--color-brand);
    margin-top: 19px;
  }

  &__name {
    font-size: var(--font-size--s);
    line-height: var(--font-size--l);
    letter-spacing: .25px;
    margin-top: 5px;
    text-align: center
  }

  &__description {
    font-size: .5rem;
    line-height: var(--font-size--m);
    letter-spacing: 0.4px;
    color: var(--color-darker);
    margin-top: 2px;
  }
}

@media (max-width: $screen-xs-max) {
  .StartedDialog {

    &__header {
      padding-left: var(--space-size--s);
    }

    &__info {
      font-size: var(--font-size--s);
      line-height: var(--font-size--m);
      padding-left: var(--space-size--s);
    }

    &__card-list {
      flex-wrap: wrap;
      height: 191px;
      align-content: space-between;
    }

    &__note {
      padding: var(--space-size--l) var(--space-size--s) var(--space-size--l);
    }

    &__card-btn {
      width: 80px;
      height: 90px;
      margin-left: var(--space-size--s);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .card-btn {

    &:hover {
      border-color: var(--color-light);
    }

    &.card-btn_selected {
      border-color: var(--color-brand);
    }

    .Icon {
      font-size: 27px;
      color: var(--color-brand);
      margin-top: var(--space-size--s);
    }

    &__name {
      font-size: var(--font-size--s);
    }
  }
}
