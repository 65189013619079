.Asset {
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--border-radius-s);
  position: relative;
  box-shadow: var(--shadow-card);
  transition: all 0.2s ease;

  &:hover {
    box-shadow: var(--shadow-card-active);
    transform: translateY(-3px);
    cursor: pointer;
    transition: all 0.2s ease;
  }

  &_wrapper {
    align-self: stretch;
    width: 100%;
  }

  &_content {
    padding: var(--space-size--s) var(--space-size--s) var(--space-size--xl);
  }

  &_image {
    height: 8rem;
    overflow: hidden;
    border-radius: var(--border-radius-s) var(--border-radius-s) 0 0;

    .Image {
      height: 8rem;
      display: block;
      margin: 0 auto;
      max-width: 100%;
      object-fit: contain;
    }

    .Image-default__container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-acсent);
      height: 100%;
    }

    .Image-default {
      width: 5rem;
      height: 5rem;
    }
  }

  &_title {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    @include text-clip;
  }

  &_subtitle {
    margin-top: var(--space-size--xs);
    font-size: var(--font-size--s);
    text-transform: uppercase;
    color: var(--color-brand);
    @include text-clip;

    .Icon {
      color: var(--color-brand);
      font-size: var(--font-size--m);
    }
  }

  &_description {
    margin-top: var(--space-size--s);
    font-size: var(--font-size--s);
    color: var(--color-gray);
    overflow: hidden;
    position: relative;
    max-height: 2.75rem;

    &:empty {
      margin-top: 0;
    }
  }

  &_type {
    color: var(--color-brand);
    margin-left: 0.5rem;
    vertical-align: middle;
  }

  &_action {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2rem;
    background-color: var(--color-acсent);
    color: var(--color-brand);
    font-size: var(--font-size--s);
    border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);

    &:hover {
      color: var(--color-focus);
    }
  }

  &_action-create {
    padding: var(--space-size--xs);

    .Icon {
      font-size: var(--font-size--m);
      display: inline-block;
      margin: -2.5px 0.5rem 0 0;
    }
  }
}

.Form_wrapperContent.Assets {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.Form.AssetsScroll {
  overflow: auto;
}

@media (min-width: $screen-xs-min) {
  .Form .Assets {
    max-width: 768px;
    margin: 0 -8px;

    .Asset_wrapper {
      width: calc(100% / 2);
      padding: 0 0.5rem var(--space-size--m);
    }
  }
}

@media (min-width: $screen-sm-min) {
  .Form .Assets {
    max-width: 768px;

    .Asset_wrapper {
      width: calc(100% / 2);
      padding: var(--space-size--xs);
    }
  }

  .Form_wrapperContent.Assets {
    margin: 0 auto;
    overflow-y: auto;
  }

  .Form.CreateItem {
    display: none;
  }

  @include scrollbar(".Assets");
}

@media (min-width: $screen-md-min) {
  .Form .Assets {
    max-width: 850px;

    .Asset_wrapper {
      width: calc(100% / 3);
    }
  }
}

@media (min-width: $screen-lg-min) {
  .Form .Assets {
    max-width: 1180px;

    .Asset_wrapper {
      width: calc(100% / 4);
    }
  }
}

@include scrollbar(".Form.AssetsScroll");
