:root {
  --font-size--xs: 0.675rem;
  --font-size--s: 0.75rem;
  --font-size: 0.875rem;
  --font-size--m: 1rem;
  --font-size--l: 1.125rem;
  --font-size--xl: 1.25rem;

  --space-size--xs: 0.5rem; // 8
  --space-size--s: 0.75rem;
  --space-size--m: 1rem;
  --space-size--l: 1.5rem;
  --space-size--xl: 2.75rem; // 44

  --form-item-height: 2.125rem;

  // material size variables
  //--MuiFormControlLabel-label
  --radio-space: 9px;

  // design distance
  --dd-from-radio-to-label: 13.33px;
}
