@import '~cssBrand/config';

.ActivitiesList {
  overflow-x: auto;
  height: 100%;
  //min-height: 600px;
  min-height: 0;
  position: relative;

  .MuiContainer-root {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  &--listView {
    height: 100%;
    overflow-y: auto;

    .MuiContainer-root {
      min-height: 100%;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
    }

    .ActivitiesList__container {
      border: none;
      border-bottom: var(--border-style);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    min-width: 80%;
    border-right: 1px solid var(--color-light);

    &:last-child {
      border-right: none;
      margin-bottom: 60px;
    }

    &--highlighted {
      background-color: var(--color-acсent);
    }

    @media screen and (min-width: 450px) {
      min-width: 60%;
    }

    @media screen and (min-width: 600px) {
      min-width: 45%;
    }

    @media screen and (min-width: 800px) {
      min-width: calc(100% / 3);
    }
  }

  &__container--Board:last-child {
    margin-bottom: 0;
  }

  &__containerHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    height: 3.5rem;
    flex-shrink: 0;
    cursor: pointer;
  }

  &__containerHeaderTitle {
    font-size: 1rem;
    font-weight: normal;
    color: var(--color-text);
  }

  &__containerHeaderChevron {
    margin-left: 0.5rem;

    &.MuiSvgIcon-root {
      width: 1.125rem;
      height: 1.125rem;
    }

    &--rotated {
      transform: rotate(180deg);
    }
  }

  &__filter {
    margin-left: auto;
  }

  &__statusColumnContent {
    flex-grow: 1;
    overflow-y: auto;

    &--withPadding {
      padding-bottom: 2rem;
    }
  }

  &__wrapper {
    margin: 0 1rem;

    &--listView {
      margin: 0;
      border-radius: var(--border-radius-m);
      overflow: hidden;
      border: var(--border-style);
    }
  }

  @include scrollbar("#{&}__statusColumnContent");
}
@include scrollbar(".ActivitiesList.ActivitiesList--listView");
