@mixin date($color, $icon) {
  display: flex;
  position: relative;
  align-items: center;
  color: $color;
  padding-left: var(--space-size--l);
  padding-right: var(--space-size--xs);
  font-size: var(--font-size--s);
  width: 6.5rem;

  &::before {
    position: absolute;
    left: 0;
    content: $icon;
    display: block;
    width: 16px;
    overflow: hidden;
    font-family: "Material Icons Outlined";
    font-size: var(--font-size--m);
  }
}

.ActivityItem {
  display: flex;
  position: relative;
  background-color: var(--color-white);
  transition: translate 0.2s ease;

  &:hover {
    cursor: pointer;
  }

  &_loader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s linear;
    z-index: 1;
    cursor: default;
    background: var(--color-bg-selected);
    opacity: 0.8;
    pointer-events: none;
  }

  &_header {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      left: -1px;
      top: 0;
      bottom: 0;
      background-color: transparent;
    }
  }

  &_title {
    font-weight: 400;
    font-size: var(--font-size);
    @include text-clip;
  }

  &_details {
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: var(--color-darker);
    font-size: var(--font-size--xs);

    @include text-clip;

    &__cut {
      max-width: 130px;
    }
  }

  &_relatedAsset {
    font-weight: 500;
    color: var(--color-brand);
    transition: color 0.3s ease;
    margin-left: 5px;
  }

  &_body {
    padding: 0 var(--space-size--m);
  }

  &_description {
    position: relative;
    margin-bottom: var(--space-size--s);
    max-height: 3.5rem;
    overflow: hidden;

    .Label {
      margin: 0;
      font-size: var(--font-size--s);
      color: var(--color-dark);
      line-height: 1.5;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 20px;
        left: 0;
        top: 2.5rem;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  &__gallery {
    display: flex;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: var(--space-size--m);
    min-height: auto;
  }

  &__imageContainer {
    margin: 0 6px;

    &:not(.ActivityItem__imageContainer_mediaItem):only-child {
      height: 8rem;
      min-height: auto;
      margin: 0 auto;
    }

    &_twoImages {
      height: 6.5rem;
      width: 50%;
    }

    &_threeImages {
      height: 5rem;
    }

    &_mediaItem {
      position: relative;
      max-width: 3.5rem;
      width: 100%;

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    border-radius: var(--border-radius-xs);

    //noinspection CssInvalidPropertyValue
    image-orientation: none; // TODO: change this deprecated property
  }

  &__item {
    width: 100%;
    height: 100%;
    border-radius: 0.72rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
  }

  &_meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--space-size--m);
    font-size: var(--font-size--s);
    letter-spacing: 0.4px;
    color: var(--color-darker);
  }

  &_unknownDate {
    @include date(var(--color-dark), "event");
    white-space: nowrap;
  }

  &_CompletedDateTime {
    @include date(var(--color-success), "check_box");
    text-decoration: line-through;

    &:before {
      text-decoration: none;
    }
  }

  .Icon {
    font-size: var(--font-size--m);
    color: var(--color-dark);
  }

  &_recurring.Icon {
    color: var(--color-brand);
  }

  &_assignContainer {
    margin-left: auto;
    /* min-width: 250px; */
    width: 40%;
    display: flex;
  }

  &_assignBuffer {
    flex-grow: 1;
    max-width: 110px;
    min-width: 0;
  }

  &_DueDateTime {
    @include date(var(--color-brand), "event");

    &_close_to_date {
      @include date(var(--color-warning), "timer");
    }

    &_overdue {
      @include date(var(--color-error), "timer_off");
    }
  }

  &__whileDragPlaceholder {
    pointer-events: none;
    opacity: 0.4;
  }
  &__whileDragPlaceholder--invisible {
    pointer-events: none;
    opacity: 0;
  }

  &--to-do {
    .ActivityItem_header:before {
      background-color: var(--color-to-do);
    }
  }

  &--in-progress {
    .ActivityItem_header:before {
      background-color: var(--color-in-progress);
    }
  }

  &--done {
    .ActivityItem_header:before {
      background-color: var(--color-done);
    }
  }
}

.without-navigation:hover {
  cursor: default;
}

@media (max-width: $screen-xs-max) {
  .ActivityItem__List {
    & .ActivityItem_details {
      display: none;
    }

    & .MuiFormControl-root {
      display: none;
    }

    & .ActivityItem_assignContainer {
      width: auto;
      order: 3;
      margin-left: 0;

      .MuiAvatar-root {
        margin-top: 2px;
      }
    }

    & .ActivityItem_meta {
      margin-left: auto;
    }
  }
}
