.FormHeader {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;

  &__title {
    font-size: 1rem;
    font-weight: 500;
  }

  &__buttons {
    margin-left: auto;
    align-self: center;
    display: flex;

    .MuiButtonGroup-contained {
      box-shadow: none;
    }
  }
}
