.ScheduledForm {
  .TwoColumnContent_formColumn {
    padding-right: var(--space-size--l);
    border-right: 1px solid var(--color-light);
  }

  &_section {
    width: 50%;

    &:first-child {
      border-right: 1px solid var(--color-light);
    }
  }

  &_text {
    display: inline-block;
    width: 3rem;
    color: var(--color-text);
  }

  .Button-link {
    margin: 0;
  }

  &__calendar {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .Calendar {
      margin-right: -0.55rem;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .ScheduledForm {

    .TwoColumnContent_formColumn {
      padding-right: 0;
      border-right: none;
    }
  }
}
