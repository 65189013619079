.EntityTabsContainer {
  border-bottom: var(--border-style);
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__Box {
    &.MuiBox-root {
      border-bottom: var(--border-style);
    }
  }

  .MuiTab-root {
    font-size: 0.75rem;
    text-transform: capitalize;
  }

  .MuiTabPanel-root {
    padding: 0;
    background-color: var(--color-bg-secondary);
    flex-grow: 1;

    @media screen and (max-width: $screen-xs-min) {
      background-color: #edf3ff;
    }
  }

  .MuiButton-root {
    margin: 0 calc(var(--space-size--m) / 2);
  }
}
