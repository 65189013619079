.Search {
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0 var(--space-size--s);
  opacity: 0;
  background-color: var(--color-brand);
  z-index: var(--z-index-menu);
  pointer-events: none;
  left: 70%;
  right: 0;
  transition: all ease-in 0.3s;

  &--expanded {
    opacity: 1;
    pointer-events: all;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__button {
  }

  &__form {
    width: 100%;
    align-self: flex-start;
    padding-top: 6px;

    .MuiInputBase-root {
      width: 100%;
      padding: 0 var(--space-size--xs);
      background-color: var(--color-white);
      border-radius: var(--border-radius-xs);

      .MuiInputBase-input {
        color: var(--color-text);
      }

      .MuiSvgIcon-root {
        width: 1rem;
        height: 1rem;
        color: var(--color-darker);
      }
    }
  }

  //.Icon {
  //  position: absolute;
  //  right: 7px;
  //  top: calc(50% - 0.5rem);
  //  color: var(--color-light);
  //}
  //
  //.Icon-active {
  //  cursor: pointer;
  //}
}
