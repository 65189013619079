.Form_item {
  .Icon {
    color: var(--color-dark);
    vertical-align: middle;

    &:not(:first-child) {
      margin-left: var(--space-size--l);
    }

    &-editable,
    &-editable + .Label {
      color: var(--color-brand);
    }
  }

  .Input {
    width: calc(100% - var(--space-size--l) - 1rem);
    margin-left: var(--space-size--m);

    &.is-cropped {
      width: calc(50% - var(--space-size--l) - 0.5rem);
    }
  }

  .Select {
    display: inline-block;
    width: calc(50% - var(--space-size--l) - 0.5rem);
    margin-left: var(--space-size--m);
  }

  .Button-link {
    min-width: 1rem;
    margin: 0.4rem 0 0.4rem var(--space-size--l);

    &:first-child {
      margin: 0.4rem 0 0.4rem -0.45rem;
    }

    & .Icon {
      font-size: inherit;
      margin-right: 1rem;
    }
  }

  &__noLabel {
    display: flex;
    padding-top: 4px;
    align-items: flex-end;
    margin-bottom: 13px;

    .Input {
      margin-top: 0;
      margin-bottom: 0;
    }

    .Select {
      margin-bottom: 0;
    }
  }

  &__scheduled {
    .Input {
      width: calc(100% - 3rem);
      margin-left: 3rem;
      margin-bottom: 2rem;
    }
  }
}
