@import '~cssBrand/config';

.Form {
  padding-bottom: 2rem;
  &.MuiContainer-root {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 600px) {
      flex-grow: 1;
      overflow: hidden;
    }
  }

  &__header {
    flex-shrink: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 600px) {
      flex-direction: row;
      flex-grow: 1;
      overflow: hidden;
      background-color: var(--color-bg-main);
      border-radius: var(--border-radius-m);
    }
  }

  &__bottomPadding {
    padding-bottom: 40px;
  }

  &__content {
    @media screen and (min-width: 600px) {
      flex-grow: 1;
      height: 100%;
      overflow-y: auto;
      border-right: var(--border-style);
    }
  }

  &__images {
    width: 100%;
    padding: 1rem;

    @media screen and (min-width: 600px) {
      max-width: 25%;
      min-width: 200px;
      display: flex;
    }
  }

  @include scrollbar("#{&}__content");
}
