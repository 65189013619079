.AssetsList {

  &__item {
    max-width: 400px;
    margin: 0.5rem;

    @media screen and (min-width: 550px) {
      max-width: calc(50% - 1rem);
    }

    @media screen and (min-width: 800px) {
      max-width: calc(100% / 3 - 1rem);
    }

    @media screen and (min-width: 1200px) {
      max-width: calc(25% - 1rem);
    }

    @media screen and (min-width: 1350px) {
      max-width: calc(20% - 1rem);
    }

    @media screen and (min-width: 1600px) {
      max-width: calc(100% / 6 - 1rem);
    }
  }
}