.ActionsBar {
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    width: 40%;

    @media screen and (min-width: 600px) {
      width: auto;
    }

    @media screen and (min-width: 800px) {
      width: calc(100% / 3);
    }
  }

  &__search {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    max-width: 200px;

    @media screen and (min-width: 800px) {
      width: calc(100% / 3);
      max-width: 400px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 60%;

    @media screen and (min-width: 600px) {
      width: auto;
    }

    @media screen and (min-width: 800px) {
      width: calc(100% / 3);
    }
  }
}
