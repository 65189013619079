.animated{-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards}
.animated.bounceIn{-webkit-animation-duration:.7s;animation-duration:.7s}
.animated.bounceOut{-webkit-animation-duration:.5s;animation-duration:.5s}
.animated.bounceIn{-webkit-animation-name:bounceIn;animation-name:bounceIn}
.animated.bounceOut{-webkit-animation-name:bounceOut;animation-name:bounceOut}
.animated.fadeIn{-webkit-animation-name:fadeIn;animation-name:fadeIn;-webkit-animation-duration:.7s;animation-duration:.7s}
.animated.fadeOut{-webkit-animation-name:fadeOut;animation-name:fadeOut;-webkit-animation-duration:.3s;animation-duration:.3s}
@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}
@keyframes fadeIn{from{opacity:0}to{opacity:1}}
@-webkit-keyframes fadeOut{from{opacity:1}to{opacity:0}}
@keyframes fadeOut{from{opacity:1}to{opacity:0}}
.rrt-confirm-holder{width:100%;height:100%;position:fixed;top:0;left:0;z-index:99999999}
.rrt-confirm-holder .shadow{width:100%;height:100%;background-color:rgba(50,58,68,0.8)}
.rrt-confirm-holder .rrt-confirm{width:320px;background-color:white;position:absolute;z-index:9;top:20%;left:50%;margin-left:-160px;box-shadow:3px 3px 20px #333;border-radius:4px;overflow:hidden}
body.toastr-confirm-active{overflow:hidden}.redux-toastr *,.redux-toastr *:before,.redux-toastr *:after{box-sizing:border-box}
.redux-toastr .top-left,.redux-toastr .top-right,.redux-toastr .top-center,.redux-toastr .bottom-left,.redux-toastr .bottom-right,.redux-toastr .bottom-center{width:320px;position:fixed;z-index:99999999;padding:0 10px}
.redux-toastr .top-left,.redux-toastr .top-right,.redux-toastr .top-center{top:0}
.redux-toastr .top-right,.redux-toastr .bottom-right{right:0}
.redux-toastr .bottom-left,.redux-toastr .bottom-right,.redux-toastr .bottom-center{bottom:0}
.redux-toastr .top-left,.redux-toastr .bottom-left{left:0}
.redux-toastr .top-center,.redux-toastr .bottom-center{left:50%;margin-left:-175px}
@media (max-width: 400px){.redux-toastr .top-right{width:280px}}
.redux-toastr .toastr{background-color:#fcfcfc;width:100%;overflow:hidden;margin:10px 0;border-radius:4px;position:relative;z-index:2;color:#333;opacity:.94;box-shadow:2px 2px 10px rgba(0,0,0,0.4)}
.redux-toastr .toastr:hover:not(.rrt-message){box-shadow:0 0 10px rgba(0,0,0,0.6);opacity:1}
.redux-toastr .toastr .toastr-status{width:100%;height:5px}
.redux-toastr .toastr .close-toastr{width:10%;height:100%;position:absolute;top:0;right:0;background-color:transparent;font-size:1rem;border:none;outline:none;opacity:0.5;cursor:pointer;font-family:"Helvetica Neue", Helvetica, Arial sans-serif;color:#000}
.redux-toastr .toastr .close-toastr:hover{opacity:1}
.redux-toastr .toastr .close-toastr:focus{outline:none}
.redux-toastr .toastr .close-toastr span{position:absolute;left:0;right:0;top:50%;transform:translateY(-50%)}
.redux-toastr .toastr.rrt-message{opacity:1;border:1px solid #dbdbdb}
.redux-toastr .toastr-attention{width:100%;height:100%;position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.4);z-index:1}
.redux-toastr .rrt-title{word-wrap: break-word};
