.Header {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: var(--space-size--xl);
  width: 100%;
  background-color: var(--color-bg-main);
  font-size: var(--font-size--m);
  border-bottom: var(--border-style);
  z-index: var(--z-index-menu);
  color: var(--color-text);

  &__column {
    display: flex;
    width: calc(100% / 3);

    &:last-child {
      justify-content: flex-end;
    }

    &--centered {
      justify-content: center;
    }
  }

  &__logo {
    margin: var(--space-size--xs);
    //width: 8rem;
    width: 100px;
    height: 1.75rem;
  }

  &__buttonSection {
    width: var(--side-menu-width);
    overflow: hidden;
    border-right: 1px solid var(--color-light);

    .MuiIconButton-root {
      padding: 10px;
      margin-left: auto;
    }
  }
}

.MobileHeader {
  display: flex;
  align-items: center;

  .MuiIconButton-root {
    color: var(--color-white);
  }

  .MuiAvatar-root {
    width: 28px;
    height: 28px;
    font-size: 1rem;
  }

  &__appBar {
    &.MuiAppBar-root {
      height: 56px;
      display: flex;
      justify-content: center;
      color: var(--color-white);
      background-color: var(--color-brand);
    }

  }

  &__button {
  }

  &__content {
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__title {
    font-size: 1.125rem;
  }

  .MuiBadge-dot {
    background-color: var(--color-warning);
  }
}
