.Title {
  float: left;
  font-size: var(--font-size--m);
  font-weight: 500;
  padding: 0.25rem 0;

  &-col {
    font-size: var(--font-size);
    text-transform: uppercase;
    margin: var(--space-size--s) var(--space-size--m);
  }
}
