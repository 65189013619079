.AccountUsers {
  &.MuiList-root {
    background-color: var(--color-bg-main);
    border: var(--border-style);
    border-radius: var(--border-radius-m);
  }

  .MuiDivider-root {
    &:last-of-type {
      display: none;
    }
  }
}