.wifi-symbol {

  $circleColor: var(--color-brand);
  $size: 30px;

  overflow: auto;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: $size;
  height: $size;

  -ms-transform: rotate(-45deg) ;
  -moz-transform: rotate(-45deg) ;
  -o-transform: rotate(-45deg) ;
  -webkit-transform: rotate(-45deg) ;
  transform: rotate(-45deg) ;

  .wifi-circle {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    font-size: $size/7;
    position: absolute;
    bottom: 0;
    left: 0;
    border-color: $circleColor;
    border-style: solid;
    border-width: 1em 1em 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 0 100% 0 0;

    opacity: 0;
    -o-animation: wifianimation 1.5s infinite;
    -moz-animation: wifianimation 1.5s infinite;
    -webkit-animation: wifianimation 1.5s infinite;
    animation: wifianimation 1.5s infinite;

    &.first {
      -o-animation-delay: 700ms;
      -moz-animation-delay: 700ms;
      -webkit-animation-delay: 700ms;
      animation-delay: 700ms;
    }

    &.second {
      width: 5em;
      height: 5em;
      -o-animation-delay: 300ms;
      -moz-animation-delay: 300ms;
      -webkit-animation-delay: 300ms;
      animation-delay: 300ms;
    }

    &.third {
      width: 3em;
      height: 3em;
    }

    &.fourth {
      border-radius: 50%;
      border: none;
      width: 1em;
      height: 1em;
      opacity: 1;
      background-color: $circleColor;
      -o-animation: none;
      -moz-animation: none;
      -webkit-animation: none;
      animation: none;
    }
  }
}


@-o-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
}

@-moz-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
}

@-webkit-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
}