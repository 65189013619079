.ActivitiesBoard_List {
  .ActivitiesBoard_Content {
    margin: 0 var(--space-size--m) 1.5rem;
    box-shadow: var(--shadow-activities);

    &:empty {
      border: none;
      margin: 0;
    }
  }

  & + .ActivitiesBoard_List {
    border-top: 1px solid var(--color-light);
  }
}

.RelatedActivities_list,
.ActivitiesBoard_List .ActivitiesList_wrapper {
  border: var(--border-style);
  border-radius: var(--border-radius-s);
  overflow-y: hidden;

  &:empty {
    border: none;
    margin: 0;
  }
}

.ActivitiesBoard_List,
.RelatedActivities_list {
  width: 100%;
  position: relative;

  .Title {
    cursor: pointer;
  }
}

.ActivityItem__List {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.75rem;
  border-bottom: var(--border-style);

  &:hover {
    background-color: var(--color-bg-selected);
  }

  .ActivityItem_header {
    display: flex;
    align-items: center;
    height: inherit;
    max-width: calc(100% - 184px);
  }

  .ActivityItem_title {
    margin-right: var(--space-size--xs);
  }
}
