.SaveButton {
  .DropDownIcon {
    transition: transform 0.25s linear;

    &_rotate {
      transform: rotate(180deg);
      transform-origin: center;
    }
  }

  &__dropdown {
    z-index: 1;
  }

  .MuiButtonBase-root {
    text-transform: none;
  }

  &__loader {
    position: absolute;
  }

  .MuiButton-root {
    min-width: 40px;
  }
}
