@mixin overlay {
  position: fixed;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

@mixin active-overlay {
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Overlay {
  @include overlay;
  z-index: var(--z-index-overlay);
  top: 0;

  &.is-active {
    @include active-overlay;
    height: 100%;
  }

  &-withHeader {
    @include overlay;
    z-index: var(--z-index-overlay);
    top: var(--space-size--xl);
  }

  &-withMobileHeader {
    @include overlay;
    z-index: var(--z-index-overlay);
    top: 56px;

    @media screen and (min-width: $screen-xs-max) {
      display: none;
    }
  }

  &-withHeader.is-active {
    @include active-overlay;
    height: calc(100% - var(--space-size--xl));
  }

  &-withMobileHeader.is-active {
    @include active-overlay;
    height: calc(100% - 56px);
  }
}
