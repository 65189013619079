.InputField {
  &__icon {
    .MuiSvgIcon-root {
      font-size: 1rem;
      color: var(--color-darker);
    }

    &_withPadding {
      &.MuiGrid-root {
        padding-top: 18px;
      }
    }
  }

  &__clear-icon {
    .MuiSvgIcon-root {
      font-size: 0.75rem;
    }
  }

  .MuiInput-root, .MuiInputLabel-root {
    font-size: var(--font-size);
  }

  .MuiInputLabel-root.MuiFormLabel-filled {
    color: var(--color-brand);
  }

  .Mui-error.MuiInputLabel-root.MuiFormLabel-filled {
    color: var(--color-error);
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 6px) scale(0.75);
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.6);
  }

  .MuiFormControl-root {
    position: relative;
  }


  .MuiMenuItem-root {
    font-size: var(--font-size);
    line-height: 1.8;
  }

  .MuiListItem-button:hover {
    background-color: var(--color-acсent);
  }

  &_multiline {
    position: relative;

    .MuiGrid-root + .MuiGrid-root {
      padding-left: 40px;
    }

    .InputField__icon {
      position: absolute;
      top: 25px;
      .MuiSvgIcon-root {
        font-size: 1rem;
        color: var(--color-darker);
      }

      &_withPadding {
        &.MuiGrid-root {
          padding-top: 18px;
        }
      }
    }

    .MuiInputBase-input {
      padding-right: 18px;
    }

    .InputField__clear-icon {
      position: absolute;
      top: 5px;
      right: 0;
    }
  }
}
