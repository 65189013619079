.RelatedActivities {
  .ActivityItem_related:last-child {
    border: none;
  }

  &_list {
    overflow: hidden;
    border-radius: var(--border-radius-s);
    margin-bottom: var(--space-size--l);
    box-shadow: var(--shadow-activities);

    &:empty {
      box-shadow: none;
      margin: 0;
    }
  }

  &__Accordion {
    padding: 0 var(--space-size--m);
  }

  .Accordion_header {
    font-size: var(--font-size--xs);
    padding-left: 0;
  }
}
