.invitation-item {
  padding: 5px 12px;

  &.MuiPaper-rounded {
    border-radius: 8px;
    margin-bottom: 10px;
  }

  &__name {
    &.MuiTypography-root {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.25px;

      margin-left: var(--space-size--xs);

      @media screen and (min-width: 800px) {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
      }
    }
  }

  &__account-to {
    &.MuiTypography-root {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.25px;
      color: var(--color-darker)
    }

    &--large {
      &.MuiTypography-root {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &__account-name {
    &.MuiTypography-root {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.1px;
      margin-left: 6px;
    }

    &--large {
      &.MuiTypography-root {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }

  &__detailsBtn {
    &.MuiButtonBase-root {
      margin-left: auto;
    }
  }

  &__details {
    &.MuiBox-root {
      margin-top: 11px;
    }
  }

  &__description {
    &.MuiTypography-root {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  &__time {
    &.MuiTypography-root {
      color: var(--color-dark)
    }
  }

  &__footer {
    &.MuiBox-root {
      margin-top: 10px;
    }
  }
}