.Accordion {
  font-size: var(--font-size);
  border-bottom: var(--border-style);
  transition: background-color ease-in 0.1s;

  &.disabled {
    color: var(--color-light);

    .Icon {
      color: var(--color-light);
    }
  }

  &_header {
    padding: var(--space-size--s) var(--space-size--m);
    cursor: pointer;
    position: relative;

    .Label {
      color: var(--color-dark);
    }

    > .Icon {
      margin-left: var(--space-size--xs);
      font-size: var(--font-size--xl);
      color: var(--color-dark);
      transform: rotate(0deg);
      transition: .3s transform;
    }

    > .Loader {
      vertical-align: middle;
      position: absolute;
      right: 48%;
    }

    .Accordion_helper {
      float: right;
    }

    .Button-link {
      margin-top: -0.5rem;
    }
  }

  &.is-opened {
    &.withBackground {
      background-color: var(--color-bg-selected);
    }

    > .Accordion_header > .Icon {
      transform: rotate(180deg);
      transition: .3s transform;
    }
  }

  .Accordion:last-child {
    border-bottom: none;
  }

  &.is-active {
    background-color: var(--color-acсent);
  }
}
