// @import "src/css/config/breakpoints";

#root,
.MainWrapper {
  height: 100%;
  min-height: 450px;
}

.MainWrapper {
  &__header {
    position: relative;
    z-index: var(--z-index-menu);
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 56px;

    @media screen and (min-width: 600px) {
      padding: var(--space-size--xl) 0 0 var(--side-menu-width);
      height: 100%;
      overflow: hidden;
    }
  }

  &__sidebar {
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
    overflow: hidden;
  }
}
