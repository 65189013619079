@mixin withIconModificator {
  &-withIcon {
    position: relative;
    padding-left: calc(var(--space-size--m) + var(--space-size--m));

    > .Icon {
      position: absolute;
      top: 1.1rem;
      left: 0;
      color: var(--color-dark);
    }
  }
}

.Section_column-withIcon > i {
  margin-top: 0.45rem;
}

.Section {
  display: flex;
  flex-direction: column;
  padding: var(--space-size--m);

  &_withBorder {
    border-bottom: var(--border-style);
  }

  &_defaultBackground {
    background-color: var(--color-bg-main);
  }

  &_row {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-size--m);

    .Textarea {
      margin-top: var(--space-size--m);
    }

    &-equalColumns {
      justify-content: space-between;

      .Section_column {
        width: auto;
      }

      @media (max-width: 599px) {
        flex-direction: column;
        align-items: flex-start;
        .Section_column_mobile {
          margin: 0;
        }
      }
    }

    &-bottomSpacing {
      margin-bottom: calc(var(--space-size--m) + var(--space-size--m));
    }

    @include withIconModificator;
  }

  &_column {
    width: 50%;
    margin: 0 var(--space-size--xs);

    @include withIconModificator;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &_row,
  &_column {
    &__doubleMargin {
      margin-bottom: calc(var(--space-size--m) * 1.5);
    }
  }

  &_row,
  &_column {
    > .Icon_edit,
    > .Radio {
      position: absolute;
      top: 0.75rem;
      left: 0;
    }
    > .Icon_edit {
      top: 1.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .Button-link {
    min-width: 6rem;
    margin-left: -0.25rem;
  }

  .Button-link .Icon {
    font-size: inherit;
    margin-right: 0.7rem;
  }
}
