.DeleteButton {
  &.MuiButton-root {
    color: var(--color-darker);

    &:hover {
      color: var(--color-error);
    }
  }

  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
}