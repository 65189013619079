.Dropzone {
  height: 100%;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:focus {
    outline: none;
  }

  &_wrapper {
    position: relative;
    flex-grow: 1;
    max-width: 100%;
    width: 100%;
    padding-bottom: 5rem;
    border: 1px var(--color-brand) dashed;
    border-radius: var(--border-radius-s);
  }

  &_img,
  &_file {
    position: relative;
    padding: var(--space-size--s);
  }

  &_file-name {
    max-width: 220px;
    text-overflow: ellipsis;
  }

  &_file {
    &-icon {
      margin-right: 3px;
      fill: var(--color-darker);
    }
  }

  &_imgPreview {
    width: 100%;

    & + & {
      margin-top: var(--space-size--m);
    }
  }

  &_imgRemove {
    position: absolute;
    right: 4px;
    top: 5px;
    border-radius: 50%;
    padding: 0 3px;
    background-color: var(--color-brand);
    color: var(--color-white);
    border: 3px solid var(--color-white);
    cursor: pointer;
  }

  &_container {
    min-height: 160px;
    padding-right: 3px;
    max-width: 100%;
    width: 100%;
  }

  &_label {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    color: var(--color-brand);
    padding-top: var(--space-size--xs);
    user-select: none;

    &-morePhoto {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color-brand);
      position: absolute;
      bottom: 1rem;
      user-select: none;
      cursor: pointer;
      width: 100%;
      text-align: center;
      white-space: nowrap;
    }
  }
}

@include scrollbar(".Dropzone");

@media screen and (max-width: 600px) {
  .Dropzone {
    overflow-y: initial;

    &_wrapper {
      // margin: var(--space-size--m);
      padding-bottom: 0;
      position: relative;

      &::-webkit-scrollbar {
        height: 5px;
      }
    }

    &_container {
      display: flex;
      justify-content: flex-start;
      overflow-x: auto;
      position: relative;

      &::-webkit-scrollbar {
        height: 5px;
      }
    }

    &_img {
      width: fit-content;
      padding: var(--space-size--l) var(--space-size--s) var(--space-size--xl);
      margin: 0 auto;
    }

    &_imgPreview {
      width: 96px;
      object-fit: fill;
    }

    &_imgRemove {
      top: 10px;
    }

    &_label,
    &_label-morePhoto {
      width: 100%;
      position: initial;
    }

    &_label-morePhoto {
      position: absolute;
      bottom: 1rem;

      .photo_icon {
        display: none;
      }
    }

    &_label {
      padding: 3.4rem;
    }
  }
  @include scrollbar(".Dropzone_container");
}

.CustomDragLayer {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
