.Body {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 56px;
  min-height: 100%;

  @media screen and (min-width: 600px) {
    padding: var(--space-size--xl) 0 0 var(--side-menu-width);
  }
  @media screen and (max-width: 600px) {
    // height: 100%;
  }

  &--disableDesktopScroll {
    @media screen and (min-width: 600px) {
      height: 100%;
      overflow: hidden;
    }
  }

  &--disableMobileScroll {
    @media screen and (max-width: 599px) {
      height: 100%;
      overflow: hidden;
    }
  }

  &--bottomPadding {
    padding-bottom: 1.875rem;
  }

  &--disableFilterPadding {
    padding-left: 0;
  }

  &--withMobileNavigation {
    padding-bottom: 60px;
  }
}
