.Tabs {

  .MuiTab-root {
    border-bottom: 1px solid var(--color-light);
  }

  .MuiTabs-indicator {
    background-color: var(--color-brand);
  }

  .MuiTabs-root {
    min-height: 0;
  }

  .MuiTab-wrapper {
    flex-direction: row;
    height: var(--space-size--l);
    text-transform: capitalize;
  }

  .Mui-selected {
    .Icon,
    .MuiTab-wrapper {
      color: var(--color-brand);
    }
  }

  .MuiTab-labelIcon {
    min-height: 3rem;
  }

  .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    margin: 0 var(--font-size--s) 0 0;
  }

  &_panel {
    padding: 0 var(--space-size--m);
    display: none;

    &.active {
      display: block;
    }
  }

  @media (max-width: $screen-xs-max) {
    & {
      .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
        margin: 0 var(--space-size--xs) 0 0;
      }

      .MuiTab-root {
        min-width: 72px;
      }

      .MuiTab-labelIcon {
        padding: 6px 0;
      }

      .MuiTab-wrapper {
        font-size: var(--font-size--s);

        .Icon {
          font-size: 0.875rem;
        }
      }
    }


  }
}
