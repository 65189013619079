.dialog {

  &__title{
    display: flex;
    align-items: center;

    .MuiTypography-root {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    .MuiSvgIcon-root {
      padding-right: 10px;
      color: var(--color-warning);
      font-size: 1.5rem;
      vertical-align: sub;
    }


  }
  &__content{
    &.MuiDialogContent-root {
      padding: 16px 16px 45px 16px;

      @media screen and(min-width: 600px) {
          padding: 24px 58px 185px 26px;
      }

    }


  }
  &__text{
    &.MuiTypography-root{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px;

      @media screen and(min-width: 600px) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin-bottom: 33px;
      }
    }
  }
  &__subtext{
    &.MuiTypography-root{
      font-size: 16px;
      line-height: 24px;


      @media screen and(min-width: 600px) {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
      }


      color: var(--color-darker);
      margin-bottom: 8px;
    }
  }
  &__actions{
    display: flex;

    @media screen and (min-width: 600px) {
      display: block;
    }
  }
  &__action-button {
    flex: 1 1 50%;

    position: relative;

    &+& {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: -8px;
        bottom: -8px;
        width: 1px;
        background-color: var(--color-on-surface3);

        @media screen and (min-width: 600px) {
         display: none;
        }
      }
    }

    @media screen and (min-width: 600px) {
      flex: none;
      &:first-child{
        border-right: none;
      }
    }

    &--default {
      &.MuiButton-root {
        color: var(--color-darker);
      }
    }

    &--primary {
      &.MuiButton-root.Mui-disabled {
        color: var(--color-acсent);
      }
    }
  }
}
