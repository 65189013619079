.Bar {
  position: relative;
  display: block;
  width: 100%;
}

.Bar:before,
.Bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: var(--color-brand);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.Bar:before {
  left:50%;
}

.Bar:after {
  right:50%;
}

.Bar--active:after,
.Bar--active:before {
  width: 50%;
}
