.RecurringForm {
  .TwoColumnContent_formColumn {
    padding: var(--space-size--l) 0 0 var(--space-size--l);
    border-left: 1px solid var(--color-light);

    .Form_item__mobileView {
      display: none;
    }
  }

  .Label {
    margin-bottom: 5px;
  }

  .Select {
    margin-top: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  .doubled {
    width: 100%;
    display: flex;
    gap: 10px;
    align-self: center;

    & .Select {
      width: calc(50% - 0.5rem);
      margin-bottom: 0;
    }
  }

  .Radio.no-label {
    display: inline-block;
  }

  .Radio_label {
    cursor: pointer;
  }

  .Radio + .Form_item {
    width: calc(100% - 2rem);
    display: inline-block;
  }

  .Radio_label,
  .Radio + .Form_item {
    .Select__single-value,
    .Input_field,
    .Label {
      color: var(--color-dark);
    }
  }

  .Radio_input:checked ~ .Radio_label,
  .Radio.checked + .Form_item {
    .Select__single-value,
    .Input_field,
    .Label {
      color: var(--color-black);
    }
  }

  .RadioGroup {
    width: 100%;

    .MuiFormControlLabel-root {
      margin: 0 0 4px calc(-1 * var(--radio-space));
    }

    .MuiFormControlLabel-label {
      margin-left: calc(var(--dd-from-radio-to-label) - var(--radio-space));

      .Select {
        margin-bottom: 0;
      }
    }

    .MuiTypography-body1 {
      font-size: inherit;
      letter-spacing: 0.25px;
      line-height: 18px;
    }

    .Input {
      margin: 0 0 0 10px;
    }
  }

  .DayOfWeek {

    &__label {
      flex-grow: 1;
    }
    &__buttons {
      position: absolute;
      top: 38px;

      &.MuiToggleButtonGroup-root {
        display: flex;
      }
    }
  }

  .RadioGroup + .RadioGroup {
    margin-top: 2rem;
  }

  .ReccuringFrequence {
    width: calc(50% - 3.25rem);
  }

  .EndDate {
    width: calc(100% - 5rem);
  }

  .RepeatCount {
    width: calc(100% - 7.5rem);
    margin-right: var(--space-size--m);
  }
}

@media (max-width: $screen-xs-max) {
  .RecurringForm .TwoColumnContent_formColumn {
    padding-left: 0;
    border-left: none;

    .Form_item__mobileView {
      display: block;
    }
  }
}
