.Textarea {
  vertical-align: middle;
  line-height: 1;
  width: 100%;

  &_field {
    box-sizing: border-box;
    padding: var(--space-size--xs) 2rem 0.25rem 0;
    display: block;
    width: 100%;
    font-size: var(--font-size);
    border: none;
    border-bottom: 1px solid var(--color-dark);
    overflow: hidden;
    resize: none;
    background-color: inherit;

    &:focus {
      outline: none;
    }

    /* active state */
    &:focus ~ .Input_label,
    &:not(:empty) ~ .Input_label {
      top: -0.5rem;
      font-size: var(--font-size--xs);
      color: var(--color-brand);
    }

    /* active state */
    &:focus ~ .Bar:before,
    &:focus ~ .Bar:after {
      width: 50%;
    }

    &.has-error {
      border-color: var(--color-error);
    }
  }

  .Input {
    display: block;
    margin-bottom: var(--space-size--s);
  }
}
