.suggestions {
  &__container {
    display: flex;
    flex-wrap: wrap;
    padding: var(--space-size--m);
    margin-left: -12px;
    margin-top: -12px;
  }

  &__cardSuggestion {
    height: 78px;
    width: calc(33.33% - var(--space-size--s));
    margin-left: var(--space-size--s);
    margin-top: var(--space-size--s);
    background-color: var(--color-white);
    @media (max-width: 768px) {
      width: calc(50% - var(--space-size--s));
    }
  }
}

.cardSuggestion {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: var(--shadow-suggestion-card);

  &:hover {
    box-shadow: var(--shadow-card-active);
    transform: translateY(-3px);
    cursor: pointer;
    transition: all 0.2s ease;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px 12px 0;
    position: relative;

    &:last-child {
      padding-bottom: 12px;
    }

    .Icon {
      color: var(--color-darker);
    }
  }

  &__remove {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 12px;
    font-size: var(--font-size--l);

    .Icon {
      transition: color ease-in-out 0.3s;
    }

    &:hover {
      .Icon {
        color: var(--color-error);
      }
    }
  }

  &__logo {
    width: 55px;
    height: 25px;
    object-fit: cover;
  }

  &__link {
    width: 100%;
    text-align: start;
    color: var(--color-brand);
    padding: 12px;
    white-space: nowrap;

    .Icon {
      margin-right: var(--space-size--xs);
      font-size: var(--font-size--m);
      padding: 0 0 4px;
    }
  }
}

@media (max-width: 464px) {
  .suggestions {
    &__container {
      margin-top: 0;
      margin-left: 0;
      flex-direction: column;
    }

    &__cardSuggestion {
      max-width: 100%;
      margin: 0 auto 12px;
      width: 100%;
      max-height: 100px;
    }
  }

  .cardSuggestion {
    &__logo_amazon,
    &__logo_ebay {
      width: 85px;
      height: 35px;
    }
  }
}
