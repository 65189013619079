.HeaderMobile {
  display: flex;
  width: 100%;
  height: 56px;
  background-color: var(--color-brand);
  padding: 0 var(--space-size--m);
  align-items: center;
  box-shadow: var(--shadow-mobile-header);

  &_navigation {
    position: relative;
    transition: all .5s;

    &-warning {
      @include indicator(-2px, -6px);
    }
  }

  &_title {
    margin-left: 32px;
    height: 22px;
    flex-grow: 1;
    font-size: var(--font-size--l);
    color: var(--color-white);
    overflow: hidden;
    transition: margin .5s;
  }

  &_search {
    width: 20px;
    transition: width .3s;

    .Button-link {
      .Icon {
        padding: 0;
      }
    }

    .Search {
      width: 0;
      height: 0;
      border: none;
      overflow: hidden;

    }

    &_active {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;

      .Search {
        margin-left: 1.25rem;
        width: 100%;
        flex-grow: 1;
        height: inherit;

        .Icon {
          color: var(--color-light);
        }
      }
    }

  }

  &_account{
    margin-left: 27px;
    overflow: hidden;
    opacity: 1;
    transition: opacity .5s;

    .Account {
      height: auto;

      &_ico {
        height: 24px;
        width: 24px;
      }
    }
  }

  &_discard {
    .Button-link {
      width: fit-content;

      .Icon {
        padding: 0 0 2px 0;
      }
    }
  }

  &_submit {
    .Button-link {
      width: 4.5rem;
    }
  }

  .Button-link{
    color: var(--color-white);
    min-width: fit-content;
    min-width: -moz-fit-content;
    min-width: -webkit-content;

    padding: 0;

    &:hover:not([disabled]) {
      background: none;
    }

    &[disabled] {
      color: var(--color-brand-light);

      .Icon {
        color: var(--color-brand-light);
      }
    }

    .Loader {
      color: var(--color-white);
    }
  }

  .Icon {
    font-size: var(--font-size--xl);
    color: var(--color-white)
  }

  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

.HeaderMobile_navigation.collapsed,
.HeaderMobile_title.collapsed,
.HeaderMobile_account.collapsed{
  overflow: hidden;
  width: 0;
  margin: 0;
  opacity: 0;
}

