.ActivitiesBoard {
  display: flex;
  height: calc(100% - 82px);
  max-width: 950px;
  margin: 0 auto;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  //scroll-snap-type: x mandatory;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &::after {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    //height: 1.5rem;
    height: 0; // turn off blackout
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(246, 245, 251, 1) 50%, rgba(255, 255, 255, 0.001) 100%);
  }

  &-ListView {
    flex-direction: column;
    overflow-y: auto;

    .Form_noResults__fixed {
      position: initial;
      margin-bottom: 1rem;
    }

    .Form_noResultsActivityImg {
      margin-top: 0;
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-size--s) var(--space-size--m);

    .Icon {
      margin-left: var(--space-size--xs);
      font-size: var(--font-size--xl);
      color: var(--color-dark);
      transform: rotate(0deg);
      transition: 0.3s transform;
    }

    &.is-opened .Icon {
      transform: rotate(180deg);
      transition: 0.3s transform;
    }
  }

  .Title {
    font-size: var(--font-size--s);
    font-weight: normal;
    text-transform: uppercase;
    margin: 0;
    padding-right: var(--space-size--m);
  }
}

@include scrollbar(".ActivitiesBoard-ListView");

.ActivitiesBoard_Board {
  width: calc(100% / 3);
  min-width: 250px;
  height: 100%;
  //scroll-snap-stop: always;

  @media screen and (max-width: 560px) {
    // custom for smooth resizing
    min-width: 45vw;
  }

  @media screen and (max-width: $screen-xs-min) {
    min-width: 70vw;
  }

  & + & {
    border-left: var(--border-style);
  }

  .ActivitiesBoard_Content {
    position: relative;
    height: calc(100% - 3rem);
    padding: 10px var(--space-size--m) 2rem;
    overflow-y: auto;
  }
}

.ActivitiesBoard_Content::-webkit-scrollbar-track {
  margin-bottom: 10px;
}

.ActivityItem__Board {
  flex-direction: column;
  border-radius: var(--border-radius-s);
  box-shadow: var(--shadow-card);
  margin-bottom: var(--space-size--m);

  &:hover {
    box-shadow: var(--shadow-card-active);
    transform: translateY(-3px);
  }

  .ActivityItem_header {
    margin: var(--space-size--m) 0;
    padding: 0 0 0 var(--space-size--m);
  }

  .ActivityItem_titleContainer {
    display: flex;
  }

  .ActivityItem_moreActions {
    margin-left: auto;
  }

  .ActivityItem_title {
    margin: 0 0 0.25rem 0;
  }

  .ActivityItem_details {
    @include text-clip;
    padding-right: var(--space-size--m);
  }

  .ActivityItem_meta {
    height: 2rem;
    background-color: var(--color-acсent);
    border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
  }
}

@include scrollbar(".ActivitiesBoard_Content");

.ActivitiesBoard_Board,
.ActivitiesBoard_List {
  &.is-active {
    background-color: var(--color-acсent);
  }

  &.centered {
    //scroll-snap-align: center;
  }
}

@media (max-width: $screen-xs-max) {
  .ActivitiesBoard::after {
    //height: 1.5rem;
  }
}
