.DayOfWeekBtn {

  &.MuiToggleButton-root  {
  height: 24px;
  padding: 0;
  min-width: 24px;
  border-radius: 50%;
  color: var(--color-brand);
  border-color: var(--color-brand);

    &:not(:first-child) {
      margin-left: 6px;
    }

    &.Mui-selected {
      background-color: var(--color-brand);
      color: var(--color-white);
      border: 1px solid var(--color-brand);
    }
  }

  &.MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
    border-radius: 50%;
  }

  &.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    border: 1px solid var(--color-brand);
    border-radius: 50%;
  }

  .MuiTouchRipple-ripple, .MuiTouchRipple-rippleVisible, .MuiTouchRipple-ripplePulsate,
  .MuiTouchRipple-child, .MuiTouchRipple-childLeaving, .MuiTouchRipple-childPulsate {
    border: 1px solid var(--color-brand);

    border-radius: 50%;
    outline: none;
  }

  .MuiToggleButton-label {
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }

  &_disabled {
    &.MuiToggleButton-root,
    &.MuiToggleButtonGroup-groupedHorizontal:not(:first-child){
      color: var(--color-dark);
      border-color: var(--color-dark);
    }

    &.Mui-selected.DayOfWeekBtn {
      background-color: var(--color-dark);
      color: var(--color-white);
      border-color: var(--color-dark);
    }
  }
}