@mixin gradient-animation {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f2f6fb;
  background: linear-gradient(to right, #e8ebf4 8%, #f2f6fb 38%, #e8ebf4 54%);
  background-size: 1000px 640px;
  position: relative;
}

.activity-cap {
  margin-bottom: 24px;
  padding: 24px 16px 16px 16px;
  border-radius: 12px;
  background-color: #f0f0f8;

  &--list-view {
    margin-bottom: 0;
    padding: 16px 16px 12px 16px;
    border-radius: 0;
    border-bottom: 1px solid #e8ebf4;

    &:first-child {
      border-radius: 12px 12px 0 0;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
      border-bottom: none;
    }

    .activity-cap__img-cap {
      display: none;
    }

    .activity-cap__text-cap-container {
      display: flex;
      margin-bottom: 0;
    }

    .activity-cap__text-cap {
      height: 10px;
    }

    .activity-cap__text-cap--title {
      width: 50%;
    }

    .activity-cap__text-cap--info {
      margin-left: auto;
      margin-right: 24px;
      width: 15%;
    }

    .activity-cap__text-cap--other {
      width: 3%;
    }
  }

  &__text-cap-container {
    margin-bottom: 8px;
  }

  &__text-cap {
    display: inline-block;
    height: 8px;
    margin-bottom: 6px;
    border-radius: 6px;
    @include gradient-animation;

    &--title {
      width: 100%;
    }

    &--info {
      width: 75%;
    }

    &--other {
      width: 65%;
    }
  }

  &__img-cap {
    height: 100px;
    border-radius: 12px;
    @include gradient-animation;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
