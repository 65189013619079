.Navigation {
  display: flex;
  justify-content: center;
  align-items: stretch;

  .MuiButton-root {
    border-radius: 0;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      left: 0;
      right: 0;
      background-color: var(--color-brand);
      bottom: 0;
      opacity: 0;
    }

    &::before {
      transform: translateY(-8px);
      transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
    }

    &::after {
      transform: translateY(4px);
      transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
    }

    &:hover {
      background: none;

      &:before,
      &:after {
        opacity: 1;
        transform: translateY(0);
      }
      &:before {
        transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
      }
      &:after {
        transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s 0.2s;
      }
    }

    &.isActive {
      &::before,
      &::after {
        opacity: 1;
        transform: translateY(0);
      }

      .MuiButton-label {
        color: var(--color-text);
      }
    }

    .MuiButton-label {
      font-size: var(--font-size);
      color: var(--color-darker);
    }
  }

  &--mobileView {
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: var(--border-style);

    .MuiButton-root {
      justify-content: flex-start;
      padding: var(--space-size--s) var(--space-size--m);

      &::before,
      &::after {
        display: none;
      }
    }

    .isActive {
      background-color: var(--color-acсent);
    }
  }
}
