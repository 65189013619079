.toastr {
  position: relative;
  padding: var(--space-size--m);
  margin-bottom: var(--space-size--m);
  background-color: var(--color-white);
  color: var(--color-text);
  font-size: var(--font-size--s);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--shadow-snack) var(--color-dark);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.rrt-error {
    &:before {
      background-color: var(--color-error);
    }

    .toastr-icon {
      fill: var(--color-error);
      width: 1rem !important;
      height: 1.3rem !important;
    }
  }

  &.rrt-warning {
    &:before {
      background-color: var(--color-warning);
    }

    .toastr-icon {
      fill: var(--color-warning);
    }
  }

  &.rrt-success {
    &:before {
      background-color: var(--color-success);
    }

    .toastr-icon {
      fill: var(--color-success);
    }
  }

  .toastr-icon {
    width: 1rem !important;
    height: 1rem !important;
  }

  .rrt-left-container {
    width: 1.5rem;
    float: left;
  }

  .rrt-middle-container {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 2.75rem;
  }
}

.redux-toastr {
  top: 2rem;
  right: var(--space-size--s);
  position: fixed;
  z-index: 1400;
}
