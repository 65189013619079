.Loader {
  position: relative;
  font-size: var(--font-size--m);
  display: inline-block;
  width: 1em;
  height: 1em;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: var(--color-brand);

  &_circle {
    height: 100%;
    width: 100%;
    font-size: inherit;
    animation: circular-bar-rotate 2s linear infinite;
  }

  &_path {
    animation: circular-bar-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 2.08%, 416%;
    stroke-dashoffset: 0;
    -webkit-transition: stroke-dasharray 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    transition: stroke-dasharray 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    fill: none;
    stroke-linecap: round;
    stroke-miterlimit: 20;
    stroke-width: 0.25rem;
    stroke: currentColor;
  }

  &-xs {
    font-size: var(--font-size--m);
  }

  &-s {
    font-size: var(--font-size--xl);
  }

  &-m {
    font-size: 1.5rem;
  }

  &-l {
    font-size: 2.25rem;
  }

  &-xl {
    font-size: 3rem;
  }
}

.LoaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75%;
}

@keyframes circular-bar-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circular-bar-dash {
  0% {
    stroke-dasharray: 2.08%, 416%;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 185.4%, 416%;
    stroke-dashoffset: -72.9%;
  }

  to {
    stroke-dasharray: 185.4%, 416%;
    stroke-dashoffset: -258.33%;
  }
}
