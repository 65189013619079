// NOTE: the order of these styles DO matter
.Calendar {
  width: fit-content;
  margin: 0 auto;

  height: 305px;
  overflow-y: hidden;

  &--separated {
    .DayPicker_transitionContainer {
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 70%;
        background-color: var(--color-light);
        top: 15%;
        left: 50%;
      }
    }
  }

  .CalendarDay {
    font-size: 12px;
  }

  .DayPicker__withBorder {
    box-shadow: none;
  }

  .CalendarMonth_table {
    border-collapse: unset;
  }

  .CalendarDay__default {
    position: relative;
    border: none;
    border-radius: 50%;
  }

  .CalendarDay__default:hover,
  .CalendarDay__today {
    background-color: transparent;
    color: var(--color-brand);
    border: none;

    &::before {
      content: "";
      display: block;
      position: absolute;
      border-radius: 50%;
      width: 38px;
      height: 37px;

      top: 0;
      left: 0;
      border: 1px solid var(--color-brand);

      @media (max-width: $screen-xs-max) {
        width: 34px;
        height: 33px;
      }
    }
  }

  .CalendarDay__today::before {
    font-weight: bold;
    border-color: var(--color-dark);
  }

  .CalendarDay__selected.CalendarDay__today::before {
    border: none;
  }

  .CalendarDay__highlighted_calendar {
    background: var(--color-acсent);
    color: #484848;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    position: relative;
    background: var(--color-brand);
    color: var(--color-white);
    z-index: 1;
  }

  .DateInput__disabled {
    background: none;
    color: var(--color-dark);
  }

  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: none;
    border: none;
    color: var(--color-dark);
  }

  .CalendarMonthGrid_month__horizontal {
    min-height: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .Calendar {
    height: 290px;

    .DayPicker__withBorder {
      margin-left: -13px;
    }
  }
}
