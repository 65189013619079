@mixin submenu-item {
  display: flex;
  flex-direction: row;
  padding: var(--space-size--s);
  // cursor: pointer;
}

.Submenu {
  position: absolute;
  top: var(--space-size--xl);
  left: 0;
  bottom: 0;
  width: 16rem;
  padding-left: var(--side-menu-width);
  background-color: var(--color-white);
  border-right: var(--border-style);
  z-index: var(--z-index-menu);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  overflow: auto;

  &:after {
    display: none;
  }

  &.is-active {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  &_header {
    padding: var(--space-size--m) var(--space-size--s);
    display: flex;
    justify-content: space-between;
    height: 100%;
    cursor: default;
  }

  &_title {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    color: var(--color-dark);
    font-size: var(--font-size--s);
  }

  &_titleName {
    display: block;
    margin-right: 4px;
    color: var(--color-brand);
    font-size: var(--font-size--m);
    max-width: 145px;

    @include text-clip;
  }

  &_iconChange {
    color: var(--color-brand);
    font-size: var(--font-size--m);
    margin-left: var(--space-size--xs);
    cursor: pointer;
    align-self: center;
  }

  &_items {
    color: var(--color-text);
    font-size: var(--font-size);
  }

  &_item {
    @include submenu-item;

    &_img,
    &_label {
      align-self: center;
      pointer-events: none;
    }

    &_label {
      @include text-clip;
    }

    &-active {
      @include submenu-item;
      background-color: var(--color-sub-menu-focus);
    }

    &:hover,
    &.is-selected {
      // background-color: var(--color-sub-menu-focus);
    }

    .Image,
    .Image-default {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: var(--border-radius-xs);
      margin-right: var(--space-size--s);
      background-color: var(--color-bg-image);
    }
  }
}

@include scrollbar('.Submenu');
