@import '~cssBrand/config';

.invitation-card {

  & + &.MuiBox-root {
    margin-top: 8px;
  }

  &__container {
    &.MuiPaper-root {
      width: 100%;
      box-shadow: none;
      padding: 2px 12px;
      border: 1px solid var(--color-on-surface3);
      border-radius: 6px;
      display: flex;
      align-items: center;

      @media screen and (min-width: $screen-xs-min) {
        padding: 2px 17px 2px 12px;
      }
    }
  }


  &__wrapper {
    &.MuiBox-root {
      width: 100%;
    }
  }

  &__email {
    display: inline-block;

    &.MuiTypography-root {
      font-weight: 400;
      margin-right: 21px;
      color: var(--color-text);

      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (min-width: $screen-xs-min) {
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }

  &__date {
    &.MuiTypography-root {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.8px;
      color: var(--color-gray);
    }
  }

  &__status {
    &.MuiTypography-root {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;

      @media screen and (min-width: $screen-xs-min) {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
      }
    }
  }

  &__action {
    margin-left: 4px;

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;

      @media screen and (min-width: $screen-xs-min) {
        height: 16px;
        width: 16px;
      }
    }
  }
}
