.MoveAsset {
  width: 100%;
  &__button {
    &.MuiButton-root {
      color: var(--color-darker);
    }
  }

  &__text {
    margin: 0 0 1rem 0;
    font-size: var(--font-size);
    color: var(--color-darker);
  }

  &__container {
    padding: 1rem;
    border-bottom: var(--border-style);
  }

  &__content {
    max-width: 400px;
  }

  &__select {
    .MuiSelect-root {
    }

    .MuiListItemText-root {
      margin: 0;
      font-size: var(--font-size);
      color: var(--color-darker);
    }

    .MuiInput-input {
      .MuiListItemIcon-root {
        display: none;
      }
    }
  }

  &__listItemIcon {
    &.MuiListItemIcon-root {
      min-width: 36px;
    }
    .MuiAvatar-root {
      width: 24px;
      height: 24px;
      background-color: var(--color-brand);
    }
  }

  &__listItemText {
    &.MuiListItemText-root {
      text-transform: capitalize;
      color: var(--color-darker);
    }
  }

  .MuiAvatar-root {
    width: 24px;
    height: 24px;
    background-color: var(--color-brand);
  }

  .MuiIconButton-root {
    margin-bottom: -8px;
  }
}
