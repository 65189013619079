.SaveConnectionPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__card {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 440px;
    height: 516px;
    margin: 0 20px;

    &.MuiPaper-rounded {
      border-radius: 8px;
    }
  }

  &__image {
    background-image: var(--save-connection);
    margin-top: 103px;
    width: 206px;
    height: 212px;
  }

  &__info {
    position: relative;
    text-align: center;
    margin-top: 64px;
    margin-bottom: 80px;
  }

  .MuiCardContent-root {
    padding: 0;
  }

  .wifi-symbol {
    left: -5px;
    top: 3px;
  }
}

@media (max-width: 600px) {
  .SaveConnectionPage {
    &__card {
      height: 380px;
      width: 360px;
    }

    &__image {
      width: 156px;
      height: 162px;
      margin-top: 70px;
    }

    &__info {
      margin-top: 44px;

      .MuiTypography-root {
        font-size: var(--font-size);
      }
    }

    .wifi-symbol {
      left: -15px;
      top: 0;
    }
  }
}
