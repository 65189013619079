@import '~cssBrand/config';

.AccountUserItem {
  max-height: 45px;

  &__ListItemAvatar {
    &.MuiListItemAvatar-root {
      min-width: 0;
      margin-right: 8px;
    }
  }

  &__Avatar {
    &.MuiAvatar-root {
      width: 26px;
      height: 26px;
      font-size: 0.825rem;
      background-color: var(--color-brand);
    }

    @media screen and (min-width: $screen-xs-min) {
      width: 24px;
      height: 24px;
    }
  }

  &__text {
    .MuiListItemText-primary {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (min-width: $screen-xs-min) {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
      }
    }
  }

  &__secondaryText {
    &.MuiTypography-root {
      color: var(--color-gray);
    }
  }

  &__action {
    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;

      @media screen and (min-width: $screen-xs-min) {
        height: 16px;
        width: 16px;
      }
    }
  }
}
