.DeleteAsset {
  color: var(--color-black);

  &_content {
    color: var(--color-black);
    font-size: var(--font-size--m); // styles from started-dialog, "StartedDialog_info"
    padding: var(--space-size--s) var(--space-size--s) 0 var(--space-size--s);

    .item-name {
      color: var(--color-brand);
    }

    .Label {
      margin-left: var(--space-size--xs);
    }
  }

  &_note {
    margin-top: 4px;
    font-size: var(--font-size--s);
    line-height: var(--font-size--l);
    color: var(--color-darker);
  }
}

.DeleteAssetActivities {
  &_header {
    margin-top: var(--space-size--xl);
    font-size: var(--font-size--s);
    line-height: var(--space-size--l);
  }

  &_parts-header {
    display: flex;
    justify-content: space-between;

    & .switch-container {
      display: flex;
      justify-content: space-between;
      width: 218px;
    }
  }

  &_content {
    margin-top: 10px;
    max-height: 181px;
    overflow: auto;
    border-radius: var(--border-radius-s);
    border: var(--border-style);
    border-bottom: none;
  }

  .RelatedActivities_list {
    margin-bottom: 0;
  }

  &_double-entity &_header {
    margin-top: var(--space-size--s);
  }

  &_double-entity &_content {
    max-height: 104px;

    & .RelatedActivities_list {
      & .related-asset-card:last-child {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
}
@include scrollbar(".DeleteAssetActivities_content");
