@mixin indicator($top: null, $right: null, $bottom: null, $left: null) {
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    border-radius: 50%;
    background-color: var(--color-warning-lighten);
  }
}
