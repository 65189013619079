.Dialog {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: var(--shadow-dialog);

  &__noShadow {
    box-shadow: none;
  }

  &_wrapper {
    @include overlay;
    @include active-overlay;
    top: 0;
    bottom: 0;
    z-index: var(--z-index-dialog);
    display: flex;
    align-items: center;
    justify-content: center;

    &__noBlackout {
      background: none;
    }
  }

  &_container-s,
  &_container-m,
  &_container-l,
  &_container-xl {
    max-height: calc(100% - 2 * var(--space-size--l));
    width: calc(100% - 2 * var(--space-size--l));
    display: flex;
    flex-direction: column;
  }

  &_container-s {
    max-width: 20rem;

    .Dialog_header {
      font-size: var(--font-size--m);
      display: flex;
      align-items: center;
      border: none;

      .Icon {
        font-size: var(--font-size--m);
      }
    }

    .Dialog_content {
      padding: 0 var(--space-size--m) 1.275rem;
      letter-spacing: 0.25px;
      line-height: 1.5rem;

      .Label {
        color: var(--color-brand);
        white-space: pre-wrap;
      }
    }

    .Dialog_actions {
      padding: 0;

      .Button {
        width: 50%;
        height: 3rem;
        border-radius: 0 0 0 5px;
      }

      .Button + .Button {
        margin: 0;
        border-left: var(--border-style);
        border-radius: 0 0 5px 0;
      }
    }
  }

  &_container-m {
    max-width: 30rem;
  }

  &_container-l {
    max-width: 35rem;
  }

  &_container-xl {
    max-width: 40rem;

    .Dialog_header {
      border-bottom: 1px solid var(--color-light);
    }

    .Dialog_actions {
      border-top: 1px solid var(--color-light);
    }
  }

  &_header {
    padding: var(--space-size--m);
    flex: 0 0 auto;
    margin: 0;
    font-weight: bold;
    border-bottom: 1px solid var(--color-light);

    .Icon {
      padding-right: 10px;
      color: var(--color-warning);
      font-size: 1.5rem;
      vertical-align: sub;
    }
  }

  &_content {
    padding: var(--space-size--m);
    flex: 1 1 auto;
    overflow-y: auto;
    font-size: var(--font-size);
  }

  &_actions {
    padding: var(--space-size--xs);
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--color-light);

    .Button {
      border: none;
    }

    .Button:focus,
    .Button:hover,
    .Button:active {
      box-shadow: none;
      background-color: var(--color-acсent);
    }

    .Button:last-child {
      &:not(:disabled) {
        color: var(--color-brand);
      }
      &:disabled {
        background-color: inherit;
      }
    }

    @media screen and (max-width: 380px) {
      justify-content: space-around;
    }
  }

  &_noGutters {
    .Dialog_header {
      display: none;
    }

    .Dialog_content {
      padding: 0;
    }
  }
}

.TwoColumnContent {
  display: flex;

  &_formColumn {
    width: 100%;
    padding-top: 1rem;

    &__withScroll {
      height: 366px;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  @include scrollbar(".TwoColumnContent_formColumn");

  &_calendarColumn {
    width: 100%;
    padding-top: 1rem;
  }
}

@include scrollbar(".Dialog_content");

@media (max-width: $screen-xs-max) {
  .Dialog {
    &_container-s,
    &_container-m,
    &_container-l,
    &_container-xl {
      width: calc(100% - 2 * var(--space-size--m));
      max-width: 420px;
    }
  }

  .TwoColumnContent_calendarColumn {
    display: none;
  }
}
