.SettingsDrawer {
  .MuiDrawer-paperAnchorDockedLeft {
    width: var(--side-menu-width);
    overflow: hidden;
  }

  &__list {
    min-width: 240px;
    height: 100%;

    &.MuiList-root {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .MuiDivider-root {
      margin-top: auto;
    }

    .MuiListItemIcon-root {
      min-width: 0;
      padding: 0 12px;
    }

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }

  &__toolBar {
    border-bottom: var(--border-style);
    &.MuiToolbar-root {
      display: flex;
      justify-content: flex-end;
      min-height: var(--space-size--xl);
    }

    .MuiIconButton-root {
      width: var(--space-size--xl);
      height: calc(var(--space-size--xl) - 1px);
    }
  }
}
