.CalendarDialog {
  z-index: 0;

  &_header {
    padding: var(--space-size--s) var(--space-size--xs);
    border-bottom: 1px solid var(--color-light);
    font-weight: normal;

    .Button-link {
      min-width: 0;
      width: 30px;
      height: 24px;
      padding-top: 2px;
      padding-bottom: 2px;
      color: var(--color-dark);
      margin-right: 5px;
    }
  }

  &_content {
    padding: 0 var(--space-size--m);

    .Form_item {
      margin: 11px 0 0 0;

      .Input {
        margin-bottom: var(--space-size--m);
      }
    }
  }
}
