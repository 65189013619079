:root {
  --form-width: 30rem;
}

.Authentication {
  &_image {
    width: calc(100% - var(--form-width));
  }

  &_form {
    position: absolute;
    top: var(--space-size--xl);
    right: 0;
    bottom: 0;
    width: var(--form-width);
    background-color: var(--color-white);
  }
}
