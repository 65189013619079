.AccountsList {
  max-height: 266px;
  overflow-y: auto;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__item, &__item.MuiButtonBase-root {
    display: flex;
    align-items: center;
    min-height: 48px;
    padding: 5px 5px 5px var(--space-size--m);
    border-top: solid var(--color-light) 1px;
    border-radius: 0;

    &_selected, &_selected.MuiButtonBase-root {
      background-color: var(--color-acсent);

      .AccountsList__accountName {
        color: var(--color-darker);
      }
    }
  }

  &__photo, &__photo.MuiAvatar-root, &__photo.MuiSkeleton-root{
    width: var(--account-photo-size);
    height: var(--account-photo-size);
    background-color: var(--color-brand);
    font-weight: 400;
    line-height: normal;

    &_default, &_default.MuiSkeleton-root {
      background-color: rgba(0, 0, 0, 0.11);
    }

    &_rounded.MuiSkeleton-root {
      border-radius: 4px;
    }
  }

  &__accountName {
    padding-left: var(--space-size--m);
    flex-grow: 1;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.75;

    &_default {
      max-width: 220px;
    }
  }

  &__users {
    .MuiAvatar-root {
      width: 18px;
      height: 18px;
      font-size: 0.75rem;
    }
  }

  &__loading {
    width: 100%;
    height: 100%;
  }
}