.AccountCard {
  position: relative;

  .MuiPaper-root {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    transform: translateY(-4px);
  }

  &__users {
    margin-top: var(--space-size--xs);
    padding: var(--space-size--xs) 0;
  }

  .MuiCardMedia-root {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 8rem;
    background-color: var(--color-acсent);

    .MuiAvatar-root {
      height: 5rem;
      width: 5rem;
      font-size: 2rem;
      background-color: var(--color-bg-image);
      font-weight: normal;
    }
  }

  .MuiTypography-caption {
    color: var(--color-darker);
    font-size: var(--font-size);
    line-height: 1.3;
    font-weight: 300;
  }

  .MuiAvatar-root {
    width: 24px;
    height: 24px;
    font-size: 0.75rem;
  }

  .MuiAvatarGroup-avatar {
    border: 2px solid var(--color-white);
  }

  .MuiCardActions-root {
    padding: 0.25rem var(--space-size--s);
    background-color: var(--color-acсent);
    color: var(--color-brand);

    .MuiSvgIcon-root,
    .MuiTypography-body1 {
      font-size: var(--font-size);
    }

    .MuiSwitch-root {
      margin-left: auto;
    }
  }
}
