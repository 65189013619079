@mixin bg-image {
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -100;
  background-position: bottom left;
}

.ServicePage {
  width: 100%;
  height: 100%;

  &_section {
    display: flex;
    height: 100%;
  }

  &_messageContainer {
    margin-bottom: 24px;
  }

  &_message {
    line-height: 22px;
    font-size: 1rem;
    color: var(--color-gray);
  }

  &_background {
    flex: 1 0 55%;

    &_wrong {
      background-image: var(--something-went-wrong);
      @include bg-image;
    }

    &_error {
      background-image: var(--not-found-404);
      @include bg-image;
    }
  }

  &_textContainer {
    flex: 1 0 40%;
    padding-top: 10%;
    order: 1;
  }

  &_textBlock {
    margin: 0 auto;
    padding: 0 30px;
    white-space: pre;
  }

  &_title {
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }
}

@media (max-width: $screen-sm-max) {
  .ServicePage {
    &_section {
      flex-direction: column;
    }

    &_background {
      order: 2;
      overflow-y: hidden;
    }

    &_textContainer {
      order: 1;
    }

    &_textBlock {
      width: fit-content;
      padding: 0 1rem;
      white-space: normal;
    }

    &_title {
      font-size: 1.5rem;
    }
  }
}
